<div style="min-height: 60vh; background-color: #111;">

    <div class="rubriques fg-white">
        <div class="row">
            <div class="cell-lg-2 cell-7">
                <div class="rubrique">
                    <b style="color: rgb(48, 164, 221);">
                        TRAP YOUR TRIP
                    </b>
                    <div (click)="admin()" class="admin">
                        Administration
                    </div>
                </div>
                <div class="rubrique showOnMObile">
                    <div>
                        Yaoundé, Cameroun
                    </div>
                    <div>
                        Tel : +237 690273220
                    </div>
                    <div>
                        Tel : +33 645136438
                    </div>
                    <div>
                        Email : contact@trapyourtrip.com
                    </div>
                </div>
            </div>
            <div class="cell-lg-2 cell-5">
                <div class="rubrique">
                    <div style="padding-bottom: 10px;">
                        <b>
                            A VOTRE ECOUTE
                        </b>
                        <div style="color: #aaa;">
                            <a routerLink="/about" style="color: rgb(48, 164, 221);">
                                Qui sommes-nous ?
                            </a>
                        </div>
                        <div style="color: #aaa;">
                            <a routerLink="/conditions" style="color: rgb(48, 164, 221);">
                                Conditions générales d’utilisation
                            </a>
                        </div>
                        <div style="color: #aaa;">
                            <a routerLink="/charte" style="color: rgb(48, 164, 221);">
                                Charte de confidentialité
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell-lg-3 cell-6">
                <div class="rubrique hideOnMObile">
                    <div>
                        Yaoundé, Cameroun
                    </div>
                    <div>
                        Tel : +237 690273220
                    </div>
                    <div>
                        Tel : +33 645136438
                    </div>
                    <div>
                        Email : contact@trapyourtrip.com
                    </div>
                </div>
            </div>
            <div class="cell-lg-3 cell-12">
                <div class="rubrique">
                    <b style="color: rgb(48, 164, 221);">
                        RESEAUX SOCIAUX
                    </b>
                    <div>
                        Facebook
                    </div>
                    <div>
                        Instagram
                    </div>
                    <div>
                        Twitter
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>