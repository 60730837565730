<div class="page" *ngIf="divertissement">
    <div class="entete">
        <div class="row">
            <div class="cell-lg-9">
                <div class="titre">
                    {{divertissement.titre}}
                </div>
                <div class="sous-titre text-leader2">
                    {{divertissement.description}}
                </div>
            </div>
            <div class="cell-lg-3 text-right">

                <div class="administration">
                    <button class="button primary" style="background-color: rgb(48, 164, 221);" (click)="ajouterActivite(divertissement)">
                            Ajouter une activité
                        </button>
                    <button class="button primary" style="background-color: rgb(48, 164, 221);margin-left: 10px;" (click)="modifier(divertissement)">
                            Modifier
                        </button>
                    <button class="button alert" style="margin-left: 10px;" (click)="supprimer(divertissement)">
                            Supprimer
                        </button>
                </div>

            </div>
        </div>
    </div>
    <div class="contenu" style="padding-top: 5vh">
        <div>
            {{divertissementItems.length}}
        </div>
        <div class="row">
            <div *ngFor="let divertissementItem of divertissementItems" class="cell-lg-4">
                <app-display-divertissement-item [divertissementItem]="divertissementItem"></app-display-divertissement-item>
            </div>
        </div>
    </div>
    <div class="contenu" style="padding-top: 5vh">
        <div class="row">
            <div *ngFor="let image of divertissement.images" class="cell-lg-4">
                <div class="trap-image" [ngStyle]="{'background-image': 'url(' + image +')'}">

                </div>
            </div>
        </div>
    </div>
</div>