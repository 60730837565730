<div class="page" style="min-height: 100vh; padding-top: 10px;">
    <div style="margin-top: 0px; margin-bottom: 20px;">

    </div>
    <div *ngIf="paiement" class="row">
        <div class="col-lg-3">
            <div class="paiement">
                <div class="paiement-titre">
                    Paiement<br>
                </div>
                <small>{{paiement?.id}}</small>
                <div class="paiement-statut">
                    <b>Date</b> : {{paiement?.date | date:'yyyy-MM-dd'}}
                </div>
                <div class="paiement-statut">
                    Payé
                    <span style="margin-left: 20px">
                        <input type="checkbox" (change)="estPayee($event)"
                            [checked]="paiement.statut && paiement.statut > 3">
                    </span>
                    <span style="margin-left: 20px; display: none;">
                        {{paiement.statut}}
                    </span>
                </div>
                <div class="paiement-mode">
                    Paiement effectué via
                    <br>
                    <b>Orange Money</b>
                </div>
                <div class="paiement-verifier">
                    <button (click)="verifier()" class="button warning" style="background-color:  rgb(48, 164, 221); color: #fff;width: 100%;">
                        Verifier le paiement
                    </button>
                </div>
                <div *ngIf="paiement.statut && paiement.statut > 3">
                    <div class="paiement-mode">
                        <b>Email de confirmation</b>
                    </div>
                    <div *ngIf="mailEnvoyee" style="margin-bottom: 20px">
                        <div style="margin-bottom: 10px">
                            Le mail de confirmation a déjà été envoyé
                        </div>
                        <button (click)="envoyerMailConfirmation()" class="button warning" style="background-color:  rgb(48, 164, 221); color: #fff;width: 100%;">
                            Renvoyer le mail de confirmation
                        </button>
                    </div>

                    <div *ngIf="!mailEnvoyee" style="margin-bottom: 20px">
                        <button (click)="envoyerMailConfirmation()" class="button warning" style="background-color:  rgb(48, 164, 221); color: #fff; width: 100%;">
                            Envoyer un mail de confirmation
                        </button>
                    </div>
                    <div style="margin-top: 20px;">
                        {{mailBienEnvoyee ? "le mail a bien été envoyé !": ""}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9">
            <div class="entete separation" *ngFor="let reservation of paiement.reservations">
                <div class="row">
                    <div class="cell-lg-8">
                        <div class="titre2">
                            <ng-container *ngIf="reservation.hebergement">
                                <b *ngIf="reservation.hebergement.prestataire" style="">
                                    {{reservation.hebergement.prestataire.nom}} -
                                </b> {{reservation.hebergement.titre}}
                                <span class="sejour__notation fg-bleu" [innerHTML]="notationToStars(reservation.hebergement.notation)"></span>
                            </ng-container>
                            <ng-container *ngIf="reservation.transport">
                                {{reservation.transport.depart.agence.nom}} :
                                <b>
                                    {{reservation.transport.depart.modele}}
                                </b>
                            </ng-container>
                            <ng-container *ngIf="reservation.divertissement">
                                {{reservation.divertissement.titre}}
                            </ng-container>
                            <ng-container *ngIf="reservation.sejour">
                                {{reservation.sejour.titre}}
                            </ng-container>
                            <ng-container *ngIf="reservation.locationVoiture">
                                Location de voiture : {{reservation.locationVoiture.voiture.modele}}
                            </ng-container>
                        </div>
                        <div class="sous-titre text-leader2">
                            <ng-container *ngIf="reservation.hebergement">
                                {{reservation.hebergement.description}}
                            </ng-container>
                            <ng-container *ngIf="reservation.transport">
                                {{description(reservation.transport.depart.trajet)}}
                            </ng-container>
                            <ng-container *ngIf="reservation.divertissement">
                                {{reservation.divertissement.description}}
                                <div>
                                    Le
                                    <b>{{reservation.divertissement.date | date: 'yyyy-MM-dd'}} </b>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="reservation.sejour">
                                {{reservation.sejour.description}}
                            </ng-container>

                            <ng-container *ngIf="reservation.locationVoiture">
                                {{reservation.locationVoiture.type}}
                                <span *ngIf="reservation.locationVoiture.type === 'interurbain'">
                                    : {{reservation.locationVoiture.depart}} - {{reservation.locationVoiture.arrivee}}
                                </span>
                                <span *ngIf="reservation.locationVoiture.type === 'location'">
                                    : {{reservation.locationVoiture.ville}}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="cell-lg-4">
                        <div class="my-text-right titre2" style="margin: 0; padding:0; text-align: right;">
                            <b>
                                {{reservation.cout | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                            </b>
                        </div>
                        <span *ngIf="reservation.transport && reservation.transport.depart.vip" class="allerretour" style="float: right; font-weight: bold;">
                            VIP
                        </span>
                    </div>
                </div>

                <div class="sejour_duree">
                    <div class="row">
                        <div class="cell-lg-3 cell-6">
                            <div class="petit-padding">
                                <ng-container *ngIf="reservation.hebergement">
                                    Hébergement
                                </ng-container>
                                <ng-container *ngIf="reservation.transport">
                                    Transport
                                </ng-container>
                                <ng-container *ngIf="reservation.divertissement">
                                    Divertissement
                                </ng-container>
                                <ng-container *ngIf="reservation.sejour">
                                    Séjour
                                </ng-container>
                                <ng-container *ngIf="reservation.locationVoiture">
                                    Location de voiture
                                </ng-container>
                            </div>
                        </div>
                        <div class="cell-lg-3 cell-6">
                            <div>
                                <ng-container *ngIf="reservation.hebergement">
                                    Durée séjour : {{duree(reservation)}} jours
                                </ng-container>
                                <div>
                                    <ng-container *ngIf="reservation.sejour">
                                        Durée séjour : {{duree(reservation)}} jours
                                    </ng-container>
                                </div>
                                <div>
                                    <ng-container *ngIf="reservation.locationVoiture">
                                        Durée de la location : {{duree(reservation)}} jours
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="cell-lg-3 cell-6">
                            <ng-container *ngIf="reservation.transport">
                                <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart !== reservation.transport.depart.trajet.villeArrivee">
                                    {{reservation.personnes}} Adultes
                                </ng-container>
                                <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart === reservation.transport.depart.trajet.villeArrivee">
                                    {{reservation.personnes}} jours
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!(reservation.transport)">
                                {{reservation.personnes}} Adultes
                            </ng-container>
                        </div>
                        <div class="cell-lg-3 cell-6">
                            <ng-container *ngIf="reservation.transport">
                                <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart !== reservation.transport.depart.trajet.villeArrivee">
                                    {{reservation.enfants ? reservation.enfants : 0}} Enfants
                                </ng-container>
                                <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart === reservation.transport.depart.trajet.villeArrivee">

                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!(reservation.transport)">
                                {{reservation.enfants ? reservation.enfants : 0}} Enfants
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div *ngIf="reservation.locationVoiture" class="row" style="margin-top: 20px;">
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Début
                            </b>
                        </div>
                        <div>
                            <ng-container>
                                {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} à {{toDate(reservation.dateDebut) | date: 'HH:mm'}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Fin
                            </b>
                        </div>
                        <div>
                            <ng-container>
                                {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} à {{toDate(reservation.dateFin) | date: 'HH:mm'}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="cell-lg-2">
                        <div style="padding-top: 5px;">

                        </div>
                    </div>
                    <div class="cell-lg-4">
                        <h3 class="muanza-text-right" style="margin: 0; padding:0">
                            {{reservation.cout | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                        </h3>
                    </div>
                </div>
                <div *ngIf="reservation.sejour" class="row" style="margin-top: 20px;">
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Arrivée
                            </b>
                        </div>
                        <div>
                            {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                        </div>
                    </div>
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Départ
                            </b>
                        </div>
                        <div>
                            {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                        </div>
                    </div>
                </div>
                <div *ngIf="reservation.hebergement" class="row" style="margin-top: 20px;">
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Arrivée
                            </b>
                        </div>
                        <div>
                            {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                        </div>
                    </div>
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Départ
                            </b>
                        </div>
                        <div>
                            {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                        </div>
                    </div>
                </div>
                <div *ngIf="reservation.transport" class="row" style="margin-top: 20px;">
                    <div class="cell-lg-3 cell-6">
                        <div>
                            <b>
                                Date de Départ
                            </b>
                        </div>
                        <div>
                            {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                        </div>
                        <h2 style="margin-top: 0px;">
                            {{toDate(reservation.dateDebut) | date: 'HH:mm' : 'fr-FR'}}
                        </h2>
                    </div>
                    <div class="cell-lg-3 cell-6">
                        <div *ngIf="reservation.transport.dateRetour">
                            <div>
                                <b>
                                    Date de retour
                                </b>
                            </div>
                            <div>
                                {{toDate(reservation.transport.dateRetour) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                            </div>
                            <h2 style="margin-top: 0px;">
                                {{toDate(reservation.transport.dateRetour) | date: 'HH:mm' : 'fr-FR'}}
                            </h2>
                        </div>
                    </div>
                    <div class="cell-lg-2">
                    </div>
                    <div class="cell-lg-4 my-text-right">
                        <div>

                            <b *ngIf="reservation.transport.retour">
                                {{reservation.personnes}} * {{reservation.transport.depart.prix * 2 |
                                currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                            </b>
                            <b *ngIf="!reservation.transport.retour">
                                {{reservation.personnes}} * {{reservation.transport.depart.prix |
                                currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                            </b>
                        </div>
                        <div *ngIf="reservation.transport.retour">
                            <span class="allerretour">
                                Aller Retour
                            </span>
                        </div>
                        <h3 class="" style="margin: 0; padding:0">
                            {{reservation.cout | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                        </h3>
                    </div>
                </div>

                <div class="shadow-1" style="padding:20px; padding-top: 15px; margin-top: 20px">
                    <div>
                        <h3 style="margin:0; padding:0;">
                            Responsable de la réservation
                        </h3>
                        <div style="color: #444;">
                            <small>Les informations suivantes sont strictement confidentielles et ne seront ni utilisées
                                ni
                                vendues</small>
                        </div>
                        <div class="row" style="margin-top: 20px;">
                            <div class="cell-lg-3 cell-6">
                                <b>Noms et prénoms</b>
                                <div>
                                    {{reservation.responsable.nom}} {{reservation.responsable.prenom}}
                                </div>
                            </div>
                            <div class="cell-lg-3 cell-6">
                                <b>Email</b>
                                <div>
                                    {{reservation.responsable.email}}
                                </div>
                            </div>
                            <div class="cell-lg-2">
                                <b>Télephone</b>
                                <div>
                                    {{reservation.responsable.indicatif}} {{reservation.responsable.tel}}
                                </div>
                            </div>
                            <div class="cell-lg-4">
                                <b>Pièce d'identification</b>
                                <div>
                                    <i *ngIf="reservation.responsable.typepiece">
                                        <ng-container *ngIf="reservation.responsable.typepiece === 'cni'">
                                            Carte Nationale d'Identité
                                        </ng-container>
                                        <ng-container *ngIf="reservation.responsable.typepiece === 'passeport'">
                                            Passeport
                                        </ng-container>
                                        <ng-container *ngIf="reservation.responsable.typepiece === 'sejour'">
                                            Carte de séjour
                                        </ng-container>
                                        <ng-container *ngIf="reservation.responsable.typepiece === 'permis'">
                                            Permis de conduire
                                        </ng-container>

                                    </i> {{reservation.responsable.numero}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>