<div class="page-sejour">
    <div class="entete2" style="">
        <div class="row">
            <div class="cell-lg-5">
                <div class="titre">
                    Réservations
                </div>
                <div class="sous-titre text-leader2">
                    Réservations dont le paiement a été initié
                </div>
            </div>
        </div>
    </div>
    <div class="contenu2 hideOnMObile">
        <table style="margin-top: -3vh; padding-top: 0;" class="table table-border cell-border">
            <thead>
                <tr>
                    <th class="hideOnMObile">Date</th>
                    <th>Date début</th>
                    <th>Prestation</th>
                    <th>Responsable</th>
                    <th style="background-color: #fafafa;">Infos</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let paiement of paiements">
                    <tr style="background-color: #eee;">
                        <td colspan="4">
                            Réservation : {{paiement.id}}

                            <div *ngIf="paiement.utilisateur">
                                <b>
                                    {{paiement.utilisateur.nom}}
                                    {{paiement.utilisateur.prenom}}
                                </b>
                            </div>
                        </td>
                        <td>
                            <button (click)="voir(paiement)" class="button success">
                                Voir
                            </button>

                            <span style="margin-left: 20px">
                                <input type="checkbox" [checked]="paiement.statut && paiement.statut > 3">
                            </span>
                            <span *ngIf="paiement.mailEnvoyee" style="margin-left: 20px;">
                                <i class="mif-mail"></i>
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let reservation of paiement.reservations">
                        <td class="hideOnMObile">{{reservation.date | date: 'yyyy-MM-dd HH:mm'}}</td>
                        <td>{{reservation.dateDebut | date: 'yyyy-MM-dd'}}</td>
                        <td>
                            <span *ngIf="reservation.sejour">
                                <b>Séjour</b>
                                <br>
                                {{reservation.sejour.titre}}
                            </span>
                            <span *ngIf="reservation.hebergement">
                                <b>Hébergement</b>
                                <br>
                                {{reservation.hebergement.titre}}
                            </span>
                            <span *ngIf="reservation.divertissement">
                                <b>Divertissement</b>
                                <br>
                                {{reservation.divertissement.titre}}
                            </span>
                            <span *ngIf="reservation.transport && reservation.transport.depart">
                                <b>Transport</b>
                                <br>
                                {{reservation.transport.depart.agence.nom}}
                            </span>
                        </td>
                        <td>
                            <div *ngIf="reservation.responsable">
                                <b>
                                    {{reservation.responsable.nom}}
                                    {{reservation.responsable.prenom}}
                                </b>
                                <div>
                                    Tel : {{reservation.responsable.email}}
                                </div>
                            </div>
                        </td>
                        <td style="text-align: right; background-color: #fafafa;">
                            <b>
                                {{reservation.cout | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                            </b>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <!-- <div class="contenu2 showOnMObile" style="width: 100vw; overflow-x: hidden;">
        <table style="margin-top: 0; padding-top: 0;" class="table table-border cell-border">
            <thead>
                <tr>
                    <th>Prestations</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let reservation of reservations">
                    <td>
                        <b>Date</b> : {{reservation.date | date: 'yyyy-MM-dd HH:mm'}}<br>
                        <b>Date début</b> : {{reservation.dateDebut | date: 'yyyy-MM-dd'}}<br>
                        <b>Prestation</b> :
                        <span *ngIf="reservation.sejour">
                            {{reservation.sejour.titre}}
                        </span>
                        <span *ngIf="reservation.hebergement">
                            {{reservation.hebergement.titre}}
                        </span>
                        <span *ngIf="reservation.divertissement">
                            {{reservation.divertissement.titre}}
                        </span>
                        <span *ngIf="reservation.transport && reservation.transport.depart">
                            {{reservation.transport.depart.agence.nom}}
                        </span>
                        <br>
                        <b>Description</b> :
                        <span *ngIf="reservation.sejour">
                            {{reservation.sejour.description}}
                        </span>
                        <span *ngIf="reservation.hebergement">
                            {{reservation.hebergement.description}}
                        </span>
                        <span *ngIf="reservation.divertissement">
                            {{reservation.divertissement.description}}
                        </span>
                        <span *ngIf="reservation.transport && reservation.transport.depart">
                            {{description(reservation.transport.depart.trajet)}}
                        </span>
                        <br>
                        <b>Coût</b> : {{reservation.cout | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}<br>

                        <div class="white-space" style="margin-top: 10px; margin-bottom: 10px;">
                            <button (click)="voir(reservation)" class="button success">
                                Voir
                            </button>
                            <button (click)="modifier(reservation)" class="button warning"
                                style="background-color: rgb(48, 164, 221);">
                                Modifier
                            </button>
                            <button (click)="supprimer(reservation.id)" class="button alert danger">
                                Supprimer
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th style="font-size: 1.2em; text-align: right;background-color: #fafafa;">
                        Total : {{TOTAL | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                    </th>
                </tr>
            </tfoot>
        </table>
        <div style="margin-top: 20px;" *ngIf="reservations && reservations.length > 0">
            <div>
                <button (click)="supprimerTout()" class="button alert danger" style="width: 100%;">
                    Annuler toutes les réservations
                </button>
            </div>
            <div>
                <button (click)="suivant()" class="button warning"
                    style="background-color: rgb(48, 164, 221); width: 100%;">
                    <ng-container *ngIf="isUser">
                        Suivant
                    </ng-container>
                    <ng-container *ngIf="!isUser">
                        Passer au paiement
                    </ng-container>

                </button>
            </div>
        </div>
    </div> -->
</div>