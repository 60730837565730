<div>
    <div class="page" *ngIf="divertissementItem">
        <div class="entete">
            <div class="row">
                <div class="cell-lg-9">
                    <div class="titre">
                        {{divertissementItem.titre}}
                    </div>
                    <div>
                        <b>
                            {{divertissementItem.divertissement.titre}}
                        </b>
                    </div>
                    <div class="sous-titre text-leader2">
                        {{divertissementItem.description}}
                    </div>
                    <div>

                        <b>{{divertissementItem.prix | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}</b>
                    </div>
                </div>
                <div class="cell-lg-3 text-right">
                    <app-admin>
                        <div class="administration">
                            <button class="button primary" style="background-color: rgb(48, 164, 221);" (click)="modifier(divertissementItem)">
                                Modifier
                            </button>
                            <button class="button alert" style="margin-left: 10px;" (click)="supprimer(divertissementItem)">
                                Supprimer
                            </button>
                        </div>
                    </app-admin>
                </div>
            </div>
        </div>
        <div class="contenu" style="padding-top: 5vh">
            <div class="row">
                <div *ngFor="let image of divertissementItem.images" class="cell-lg-4">
                    <div class="trap-image" [ngStyle]="{'background-image': 'url(' + image +')'}">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>