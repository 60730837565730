import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { DivertissementItem } from 'src/app/models/divertissement.item.model';
declare const metro: any;

@Component({
  selector: 'app-divertissement-item-view',
  templateUrl: './divertissement-item-view.component.html',
  styleUrls: ['./divertissement-item-view.component.scss']
})
export class DivertissementItemViewComponent implements OnInit {

  divertissementItem: DivertissementItem;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const id = paramMap.get('id');
      if (id) {
        this.getItem(id);
      }
    });
  }

  getItem(id: string) {
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection('divertissements-item-trap').doc(id).get().then((resultat) => {
        const divertissementItem = resultat.data() as DivertissementItem;
        this.divertissementItem = divertissementItem;
        console.log('TERMINEEE !!!');
        console.log(this.divertissementItem);
        metro().activity.close(activity);
      }).catch((e) => {
        metro().activity.close(activity);
      });
    });
  }

  modifier(element) {
    this.router.navigate(['offres/divertissement/loisirs/item/edit/', element.id]);
  }

  supprimer(element) {
    const oui = confirm('Etes vous sûr de vouloir supprimer cet élément ?');
    const db = firebase.firestore();
    db.collection('divertissements-item-trap').doc(element.id).delete().then(() => {
      this.router.navigate(['offres/divertissement/loisirs/view', this.divertissementItem.divertissement.id]);
    });
  }

}
