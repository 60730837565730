<div class="page" style="min-height: 100vh; padding-top: 10px;">

    <h1>
        Liste des villes

    </h1>


    <div class="contenu">
        <div style="margin-top: 10px; margin-bottom: 10px;">
            <button class="button" routerLink="/ville/edit">
                Ajouter une ville
            </button>
        </div>
        <div>
            <table class="table table-border cell-border">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nom</th>
                        <th>Pays</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ville of villes">

                        <td>{{ville.id}}</td>
                        <td>{{ville.nom}}</td>
                        <td>{{ville.pays}}</td>
                        <td>
                            <button (click)="supprimer(ville)" class="button danger">Supprimer</button>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>