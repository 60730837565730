<div #menuMobile data-role="appbar" [ngClass]="{'shadow-1': !primarycolor || mobile}" data-expand-point="md" class="muanza-appbar" style="">
    <a href="#" class="brand no-hover">

        <img class="image-logo" src="../../../assets/img/Trap Your Trip Logo 2.png">
    </a>

    <ul class="app-bar-menu ml-auto" [ngClass]="{'onscroll': !primarycolor || mobile}" (click)="hamburger()">
        <li>
            <a routerLink="accueil" routerLinkActive="active">
                <span class="mif-home"></span> Accueil
            </a>
        </li>
        <li>
            <a routerLink="taux" routerLinkActive="active">
                <span class="mif-money"></span> Taux de conversion
            </a>
        </li>
        <li>
            <a routerLink="ville" routerLinkActive="active">
                <span class="mif-location-city"></span> Villes
            </a>
        </li>
        <li>
            <a [ngClass]="{'active': offres}" (click)="check()" class="dropdown-toggle">
                <span class="mif-star-full"></span> Nos offres
            </a>
            <ul style="z-index: 2000;" class="d-menu" data-role="dropdown" style="background-color: #303030;">
                <li>
                    <a routerLink="/offres/sejour" routerLinkActive="active">
                        Séjours
                    </a>
                </li>
                <li>
                    <a routerLink="/offres/hebergement" routerLinkActive="active">
                        Hébergements
                    </a>
                </li>
                <li>
                    <a routerLink="/offres/transport" routerLinkActive="active">
                        Transport
                    </a>
                </li>
                <li>
                    <a routerLink="/location" routerLinkActive="active">
                        Location de voiture
                    </a>
                </li>
                <li>
                    <a routerLink="/offres/divertissement" routerLinkActive="active">
                        Divertissement
                    </a>
                </li>
            </ul>
        </li>
        <li>
            <a routerLink="panier" routerLinkActive="active">
                <span class="mif-cart"></span> Réservations
                <b class="superieur" style="margin-left: 3px;">
                    <sup>{{reservations.length}}</sup>
                </b>
            </a>
        </li>

        <li *ngIf="admin" class="muanza-padding-droit">
            <a class="dropdown-toggle">
                <span class="mif-cog fg-blue"></span> Administration
            </a>
            <ul style="z-index: 2000;" class="d-menu" data-role="dropdown" style="background-color: #303030;">

                <li>
                    <a>
                        Administrateurs
                    </a>
                </li>
                <li>
                    <a (click)="deconnexionAdmin()">
                        Déconnexion
                    </a>
                </li>
            </ul>
        </li>
        <li *ngIf="!utilisateur && !admin" class="muanza-padding-droit">
            <a (click)="adminConnexion()">
                <span class="mif-security"></span> Connexion
            </a>
        </li>
        <li *ngIf="utilisateur" class="muanza-padding-droit">
            <a class="dropdown-toggle" [ngClass]="{'active': dashboard}">

                <span class="nom-user">
                    <i *ngIf="mobile" class="mif-user" style="margin-right: 10px;"></i>
                    
                    {{utilisateur ? utilisateur.nom :'Mon'}}
                    {{utilisateur ? utilisateur.prenom :'compte'}}
                </span>
                <img [src]="photoURL" class="user-image">
            </a>
            <ul style="background-color: #303030; z-index: 2000; margin-left: 0px; padding-left: 0px;" class="d-menu" data-role="dropdown">
                <li>
                    <a routerLink="/dashboard/profil" routerLinkActive="active">
                        Mon profil
                    </a>
                </li>
                <li>
                    <a routerLink="/dashboard/compte" routerLinkActive="active">
                        Mon compte
                    </a>
                </li>
                <li>
                    <a routerLink="/dashboard/reservation" routerLinkActive="active">
                        <ng-container *ngIf="!utilisateur.prestataire">
                            Mes réservations
                        </ng-container>
                        <ng-container *ngIf="utilisateur.prestataire">
                            Réservations
                        </ng-container>
                    </a>
                </li>
                <li *ngIf="utilisateur.prestataire">
                    <a [routerLink]="['offres', 'hebergement', 'prestataire', utilisateur.id]">
                        Mes offres
                    </a>
                </li>
                <li>
                    <a routerLink="/dashboard/paiement" routerLinkActive="active">
                        <ng-container *ngIf="!utilisateur.prestataire">
                            Mes paiements
                        </ng-container>
                        <ng-container *ngIf="utilisateur.prestataire">
                            Paiements
                        </ng-container>
                    </a>
                </li>
                <li>
                    <a (click)="deconnexion()">
                        Déconnexion
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>