<div style="background-color: #eee; height: 320px;">

</div>
<div class="inscription-page" style="margin-top: -320px;">
    <div class="row">
        <div class="cell-lg-5">
            <div class="titre">
                Inscrivez-vous !
            </div>
            <div class="text-leader2">
                Créez un compte et profitez de toutes nos offres !
                Si vous possédez déjà un compte
                <a routerLink="/connexion">Cliquez-ici</a>
            </div>
        </div>
        <div class="cell-lg-1"></div>
        <div class="cell-lg-5">
            <div class="formulaire shadow-1">
                <div class="text-leader2 text-center" style="margin-bottom: 30px;">
                    Veuillez remplir ce formulaire
                </div>
                <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                    <div>
                        <input class="hideOnMObile" formControlName="nom" data-role="input" data-prepend="Nom">
                        <input class="showOnMObile" formControlName="nom" data-role="materialinput"
                            placeholder="Votre nom">
                    </div>
                    <div>
                        <input class="hideOnMObile" formControlName="prenom" data-role="input" data-prepend="Prénom">
                        <input class="showOnMObile" formControlName="prenom" data-role="materialinput"
                            placeholder="Votre prénom">
                    </div>
                    <div>
                        <select formControlName="sexe" data-role="select" data-prepend="Sexe" data-filter="false">
                            <option value="femme">
                                Femme
                            </option>
                            <option value="homme">
                                Homme
                            </option>
                        </select>
                    </div>
                    <div>
                        <input class="hideOnMObile" formControlName="login" data-role="input" data-prepend="Email">
                        <input class="showOnMObile" formControlName="login" data-role="materialinput"
                            placeholder="Votre adresse mail">
                        <small class="fg-red">
                            {{messageEmail}}
                        </small>
                    </div>
                    <div>
                        <input class="hideOnMObile" formControlName="passe" type="password" data-role="input"
                            data-prepend="Mot de passe">
                        <input class="showOnMObile" formControlName="passe" type="password" data-role="materialinput"
                            placeholder="Mot de passe">
                    </div>
                    <div>
                        <input class="hideOnMObile" formControlName="confirmation" type="password" data-role="input"
                            data-prepend="Confirmer mot de passe">
                        <input class="showOnMObile" formControlName="confirmation" type="password"
                            data-role="materialinput" placeholder="Confirmez le mot de passe">
                        <small class="fg-red">
                            {{messagePasse}}
                        </small>
                    </div>
                    <div>
                        <button class="button warning" style="background-color: rgb(48, 164, 221);width: 100%;">
                            S'inscrire
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>