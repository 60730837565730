import { Component, OnInit } from '@angular/core';
import { Ville } from 'src/app/models/ville.model';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

declare const metro: any;

@Component({
  selector: 'app-ville-list',
  templateUrl: './ville-list.component.html',
  styleUrls: ['./ville-list.component.scss']
})
export class VilleListComponent implements OnInit {

  villes = new Array<Ville>();
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getVilles();
  }

  getVilles() {
    this.villes = new Array<Ville>();
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('ville-trap').get().then((resultats) => {
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
      resultats.forEach((resultat) => {
        const ville = resultat.data() as Ville;
        this.villes.push(ville);
      });
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

  supprimer(ville: Ville) {

    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('ville-trap').doc(ville.id).delete().then(() => {
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
      this.getVilles();

    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

}
