import { formatNumber } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { Paiement } from '../models/paiement.model';
import { Reservation } from '../models/reservation.model';
import { Trajet } from '../models/trajet.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  lienback = '';

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.lienback = environment.lienback;
  }

  envoyerMailConfirmation(paiement: Paiement) {
    return new Promise((resolve, reject) => {
      console.log('envoyerMailConfirmation');
      // tslint:disable-next-line:prefer-for-of
      // for (let i = 0; i < paiement.reservations.length; i++) {
      const reservation = paiement.reservations[0];
      if (reservation.responsable.email) {

        const lesnoms = reservation.responsable.nom + ' ' + reservation.responsable.prenom;
        let parametres = new HttpParams();
        parametres = parametres.append('email', reservation.responsable.email);
        parametres = parametres.append('noms', lesnoms);
        parametres = parametres.append('contenu', this.genererContenu(lesnoms, paiement));
        console.log('parametres');
        console.log(parametres.keys());

        const lesheaders = new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        });
        const options = {
          headers: lesheaders,
          params: parametres,
          withCredentials: false
        };
        console.log('options');
        console.log(options);
        // tslint:disable-next-line:max-line-length
        this.http.post('/admintrap/sendmail.php', { responseType: 'text' }, options)
          .subscribe((response) => {
            console.log('response');
            console.log(response);
            paiement.mailEnvoyee = true;
            const db = firebase.firestore();
            db.collection('paiement-trap')
              .doc(paiement.id).set(JSON.parse(JSON.stringify(paiement))).then((resultat) => {
                resolve(true);
              }).catch((e) => {
                resolve(false);
              });
          }, (error) => {
            console.log('error');
            console.log(error);
            reject(error);
          });
      } else {
        reject('Aucune addresse mail associée à ce compte');
      }
      // }
    });
  }

  genererContenu(noms: string, paiement: Paiement): string {
    let contenuHTML = '<html>';
    // HEADERS
    contenuHTML += '<head>';
    contenuHTML += '<meta http-equiv=`Content-Type` content=`text/html; charset=utf-8` />';
    contenuHTML += '<title>TRAP YOUR TRIP</title>';
    contenuHTML += '</head>';
    // BODY
    contenuHTML += '<body>';
    contenuHTML += '<img src=`https://firebasestorage.googleapis.com/v0/b/trapyourtrip.appspot.com/o/logos%2Flogotrapyourtrip.png?alt=media&token=eacf4b2e-0097-4654-9b10-219126e00e5b` style=\'height: 80px; margin-bottom: 20px\'>';
    contenuHTML += '<p><b style=\'color: rgb(48, 164, 221); font-size: 14px;\'>Merci ' + noms + ' pour votre réservation,</b></p>'
      + '<p>Vous trouverez ci-dessous le détail de votre réservation.</p>';
    // TABLE
    contenuHTML += '<table style=\'border-spacing: 0;width: 800px;\'>';
    // THEAD
    contenuHTML += '<thead><tr>';
    contenuHTML += '<th style=\'border: solid lightgray 1px; width: 600px;padding: 5px;text-align: left;\'>Prestation</th>';
    contenuHTML += '<th style=\'border: solid lightgray 1px; width: 100px;padding: 5px;text-align: right;\'>Quantité (en jours)</th>';
    contenuHTML += '<th style=\'border: solid lightgray 1px; width: 100px;padding: 5px;text-align: right;\'>Prix Total</th>';
    contenuHTML += '</tr></thead>';
    // THEAD
    contenuHTML += '<tbody>';
    let total = 0;
    paiement.reservations.forEach((reservation) => {
      contenuHTML += this.ligne(reservation);
      total += reservation.cout;
    });
    contenuHTML += '<tr>';
    contenuHTML += '<td style=\'border: solid lightgray 1px; width: 600px;padding: 5px;\'>Total</td>';
    contenuHTML += '<td style=\'border: solid lightgray 1px; width: 600px;padding: 5px;text-align: right;\'></td>';
    contenuHTML += '<td style=\'border: solid lightgray 1px; width: 600px;padding: 5px;text-align: right;\'>' + total + ' FCFA</td>';
    contenuHTML += '</tr>';
    contenuHTML += '</tbody>';
    contenuHTML += '</table>';
    contenuHTML += '<p style=\'margin-top:20px;\'><b>Informations importantes</b></p>';
    contenuHTML += '<ul style=\'border-spacing: 0;width: 700px;\'>'
      + '<li>Vous devrez présenter une pièce d’identité avec photo à l’accueil à votre arrivée</li>'
      + '<li>Vous pouvez modifier ou annuler votre réservation en toute simplicité en contactant le prestataire directement ou en nous contactant aux coordonnées ci-dessous. Des frais d`annulation peuvent s`appliquer selon les conditions de votre réservation</li>'

      + '</ul>';
    contenuHTML += '<p>Bien à vous chèr(e) voyageur et merci d’utiliser notre plateforme.</p>';
    contenuHTML += '<p style=\'color: rgb(48, 164, 221); font-size: 14px;\'>sales@trapyourtrip.com</p>'
      + '<p>Tel : +237 6 67 04 12 25</p>'
      + '<p>Tel : +237 6 90 15 61 31</p>'
      + '<p>Tel : +33 6 45 13 64 38</p>';
    // tslint:disable-next-line:max-line-length
    // con
    contenuHTML += '</body>';
    contenuHTML += '</html>';
    console.log(contenuHTML);

    return contenuHTML;
  }

  stringType(reservation: Reservation): string {
    let contenuHTML = '';
    if (reservation.hebergement) {
      contenuHTML += '<div><b>Hébergement</b></div>';
    }
    if (reservation.sejour) {
      contenuHTML += '<div><b>Séjour</b></div>';
    }
    if (reservation.transport) {
      contenuHTML += '<div><b>Transport</b></div>';
    }
    if (reservation.divertissementItem) {
      contenuHTML += '<div><b>Transport</b></div>';
    }
    if (reservation.locationVoiture) {
      if (reservation.locationVoiture.type === 'interurbain') {
        contenuHTML += '<div><b>Location de voiture - Interurbain</b></div>';
      } else {
        contenuHTML += '<div><b>Location de voiture</b></div>';
      }
    }
    if (reservation.divertissement) {
      if (reservation.divertissement.date) {
        contenuHTML += '<div><b>Evènement</b></div>';
      } else if (reservation.divertissement.restaurant) {
        contenuHTML += '<div><b>Divertissement - Restaurant</b></div>';
      } else {
        contenuHTML += '<div><b>Divertissement - Attractions</b></div>';
      }
    }
    return contenuHTML;
  }

  stringNomPrestataire(reservation: Reservation): string {
    let contenuHTML = '';
    if (reservation.hebergement) {
      contenuHTML += '<div>' + reservation.hebergement.prestataire.nom + '</div>'
        + '<div>' + reservation.hebergement.prestataire.ville + '</div>';
    }
    if (reservation.sejour) {
      contenuHTML += '<div>' + reservation.sejour.titre + '</div>'
        + '<div>' + reservation.sejour.ville + '</div>';
    }
    if (reservation.transport) {
      contenuHTML += '<div>' + reservation.transport.depart.agence.nom + '</div>'
        + '<div>' + this.description(reservation.transport.depart.trajet) + '</div>';
    }
    if (reservation.locationVoiture) {
      if (reservation.locationVoiture.type === 'interurbain') {
        contenuHTML += '<div>' + reservation.locationVoiture.voiture.modele + '</div>';
        contenuHTML += '<div>' + reservation.locationVoiture.ville + '</div>';
      } else {
        contenuHTML += '<div>' + reservation.locationVoiture.voiture.modele + '</div>';
        contenuHTML += '<div>Ville de départ :' + reservation.locationVoiture.depart + '</div>';
        contenuHTML += '<div>Ville d’arrivée :' + reservation.locationVoiture.arrivee + '</div>';
      }
    }
    if (reservation.divertissement) {
      if (reservation.divertissement.date) {
        contenuHTML += '<div>' + reservation.divertissement.titre + '</div>';
      } else {
        contenuHTML += '<div>' + reservation.divertissement.titre + '</div>';
      }
    }
    if (reservation.divertissementItem) {
      contenuHTML += '<div>'
        + reservation.divertissementItem.divertissement.titre
        + ' - ' + reservation.divertissementItem.titre + '</div>';
    }
    return contenuHTML;
  }

  ligne(reservation: Reservation): string {

    console.log('reservation');
    console.log(reservation);
    let contenuHTML = '';
    contenuHTML += '<tr>';
    contenuHTML += '<td style=\'border: solid lightgray 1px; width: 600px;padding: 5px;\'>';
    contenuHTML += this.stringType(reservation);

    contenuHTML += this.stringNomPrestataire(reservation);

    if (reservation.personnes) {
      contenuHTML += '<div>' + reservation.personnes + ' personnes</div>';
    }

    if (reservation.dateDebut) {
      console.log('reservation.dateDebut');
      console.log(reservation.dateDebut);
      contenuHTML += '<div>Date d’arrivée : ' + new Date(reservation.dateDebut).toISOString().split('T')[0] + '</div>';
    }
    if (reservation.dateFin) {
      contenuHTML += '<div>Date de départ : ' + new Date(reservation.dateFin).toISOString().split('T')[0] + '</div>';
    }

    contenuHTML += '</td>';
    contenuHTML += '<td style=\'border: solid lightgray 1px; width: 600px;padding: 5px;text-align: right;\'>'
      + this.duree(reservation)
      + '</td>';
    contenuHTML += '<td style=\'border: solid lightgray 1px; width: 600px;padding: 5px;text-align: right;\'>'
      + formatNumber(reservation.cout, this.locale)
      + ' FCFA</td>';
    contenuHTML += '</tr>';
    return contenuHTML;
  }

  entete(): string {
    let tr = '';
    tr += '';
    return tr;
  }

  description(trajet: Trajet) {
    if (trajet) {
      if (trajet.villeArrivee === trajet.villeDepart) {
        return 'Location de voiture : ' + trajet.villeArrivee;
      } else {
        return trajet.villeDepart + ' - ' + trajet.villeArrivee;
      }
    } else {
      return '';
    }
  }

  duree(reservation: Reservation) {
    const dateFin = reservation.dateFin;
    const date = reservation.dateDebut;

    if (dateFin && date) {
      const diff = new Date(dateFin).getTime() - new Date(date).getTime();

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));

      return days;
    } else {
      return 1;
    }
  }

}
