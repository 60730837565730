import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase';
import { Sejour } from 'src/app/models/sejour.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Hebergement } from 'src/app/models/hebergement.model';
import { Prestataire } from 'src/app/models/prestataire.model';
import { Utilisateur } from 'src/app/models/utilisateur.model';
import { Ville } from 'src/app/models/ville.model';
declare const metro: any;

@Component({
  selector: 'app-hebergement-edit',
  templateUrl: './hebergement-edit.component.html',
  styleUrls: ['./hebergement-edit.component.scss']
})
export class HebergementEditComponent implements OnInit {

  @Input() hebergement?: Hebergement;
  form: FormGroup;
  fichiers: FileList;
  images = new Array<Blob>();
  liens = new Array<string>();
  prestataires = new Array<Utilisateur>();
  prestataire: Utilisateur;
  villes = new Array<Ville>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVilles();
    this.getPrestataires().then((prestataires) => {
      this.prestataires = prestataires;
      this.route.paramMap.subscribe((paramMap) => {
        const id = paramMap.get('id');
        const idprestataire = paramMap.get('idprestataire');
        if (id) {
          const db = firebase.firestore();
          db.collection('hebergements-trap').doc(id).get().then((resultat) => {
            const h = resultat.data() as Hebergement;
            this.hebergement = h;
            this.initForm();
          });
        }

        if (idprestataire) {
          this.prestataires.forEach((p) => {
            if (p.id === idprestataire) {
              this.prestataire = p;
              this.initForm();
            }
          });
        }
      });
    });

  }

  getVilles() {
    this.villes = new Array<Ville>();
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('ville-trap').get().then((resultats) => {
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
      resultats.forEach((resultat) => {
        const ville = resultat.data() as Ville;
        this.villes.push(ville);
      });
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

  getPrestataires(): Promise<Array<Utilisateur>> {
    return new Promise((resolve, reject) => {
      const prestataires = [];
      const db = firebase.firestore();
      db.collection('utilisateurs-trap').get().then((resultats) => {
        console.log('TERMINEEE !!!');
        resultats.forEach((resultat) => {
          const prestataire = resultat.data() as Utilisateur;
          if (prestataire.prestataire) {
            prestataires.unshift(prestataire);
          }
        });
        resolve(prestataires);
        // this.initForm();
      }).catch((e) => {
        reject(e);
      });
    });
  }

  retirerImage(image) {
    const oui = confirm('Etes-vous sûr de vouloir supprimer cette image ?');
    if (oui) {
      const images = [];
      this.hebergement.images.forEach((img) => {
        if (image !== img) {
          images.push(img);
        }
      });
      this.hebergement.images = images;
    }
  }

  initForm() {
    let prestataire;
    this.prestataires.forEach((p) => {
      if (this.hebergement && this.hebergement.prestataire && p.id === this.hebergement.prestataire.id) {
        prestataire = p;
      }
    });
    if (!prestataire) {
      this.prestataires.forEach((p) => {
        if (this.prestataire && this.prestataire.id === p.id) {
          prestataire = p;
        }
      });
    }
    console.log('le dernier prestataire');
    console.log(prestataire);
    this.form = this.formBuilder.group({

      titre: [this.hebergement ? this.hebergement.titre : '', [Validators.required]],
      description: [this.hebergement ? this.hebergement.description : '', [Validators.required]],
      descriptionSuccincte: [this.hebergement ? this.hebergement.descriptionSuccincte : '', [Validators.required]],

      titreENG: [this.hebergement ? this.hebergement.titreENG : '', [Validators.required]],
      descriptionENG: [this.hebergement ? this.hebergement.descriptionENG : '', [Validators.required]],
      descriptionSuccincteENG: [this.hebergement ? this.hebergement.descriptionSuccincteENG : '', [Validators.required]],

      nuitee: [this.hebergement ? this.hebergement.nuitee : '', [Validators.required]],
      lieu: [this.hebergement ? this.hebergement.lieu : '', [Validators.required]],
      tel: [this.hebergement ? this.hebergement.tel : '', [Validators.required]],
      pays: [this.hebergement ? this.hebergement.pays : '', [Validators.required]],
      ville: [this.hebergement ? this.hebergement.ville : prestataire ? prestataire.ville : '', [Validators.required]],
      prestataire: [prestataire, []],

      baignoire: [this.hebergement && this.hebergement.options ? this.hebergement.options.baignoire : false],
      wifi: [this.hebergement && this.hebergement.options ? this.hebergement.options.wifi : false],
      climatiseur: [this.hebergement && this.hebergement.options ? this.hebergement.options.climatiseur : false],
      bureau: [this.hebergement && this.hebergement.options ? this.hebergement.options.bureau : false],
      linge: [this.hebergement && this.hebergement.options ? this.hebergement.options.linge : false],
      tele: [this.hebergement && this.hebergement.options ? this.hebergement.options.tele : false],
      insonore: [this.hebergement && this.hebergement.options ? this.hebergement.options.insonore : false],
      bouilloire: [this.hebergement && this.hebergement.options ? this.hebergement.options.bouilloire : false],
      cafe: [this.hebergement && this.hebergement.options ? this.hebergement.options.cafe : false],
      minibar: [this.hebergement && this.hebergement.options ? this.hebergement.options.minibar : false],
      litdouble: [this.hebergement && this.hebergement.options ? this.hebergement.options.litdouble : false],
      litsimple: [this.hebergement && this.hebergement.options ? this.hebergement.options.litsimple : false],
      spa: [this.hebergement && this.hebergement.options ? this.hebergement.options.spa : false],
      forme: [this.hebergement && this.hebergement.options ? this.hebergement.options.forme : false],
      navette: [this.hebergement && this.hebergement.options ? this.hebergement.options.navette : false],

      notation: [this.hebergement ? this.hebergement.notation : '3', [Validators.required]],
      tags: [this.hebergement ? this.hebergement.tags : ''],
    });

    this.form.controls.prestataire.valueChanges.subscribe((val) => {
      console.log('preestataires');
      console.log(val);
    });
  }

  onFormSubmit() {
    const value = this.form.value;

    const titre = value.titre;
    const description = value.description;
    const descriptionSuccincte = value.descriptionSuccincte;

    const titreENG = value.titreENG;
    const descriptionENG = value.descriptionENG;
    const descriptionSuccincteENG = value.descriptionSuccincteENG;

    const nuitee = value.nuitee;
    const lieu = value.lieu;
    const pays = value.pays;
    const ville = value.ville;
    const tel = value.tel;
    const wifi = value.wifi;
    const parking = value.parking;
    const notation = value.notation;
    const prestataire = value.prestataire;
    if (prestataire) {
      let hebergement = new Hebergement();
      if (this.hebergement) {
        hebergement = this.hebergement;
      }

      hebergement.titreENG = titreENG;
      hebergement.descriptionENG = descriptionENG;
      hebergement.descriptionSuccincteENG = descriptionSuccincteENG;

      hebergement.titre = titre;
      hebergement.description = description;
      hebergement.descriptionSuccincte = descriptionSuccincte;

      hebergement.nuitee = nuitee;
      hebergement.lieu = lieu;
      hebergement.pays = prestataire.pays;
      hebergement.ville = prestataire.ville;
      hebergement.tel = tel;
      hebergement.wifi = wifi;
      hebergement.parking = parking;
      hebergement.notation = notation;
      hebergement.prestataire = prestataire;

      hebergement.options.baignoire = value.baignoire;
      hebergement.options.wifi = value.wifi;
      hebergement.options.climatiseur = value.climatiseur;
      hebergement.options.bureau = value.bureau;
      hebergement.options.linge = value.linge;
      hebergement.options.tele = value.tele;
      hebergement.options.insonore = value.insonore;
      hebergement.options.bouilloire = value.bouilloire;
      hebergement.options.cafe = value.cafe;
      hebergement.options.minibar = value.minibar;
      hebergement.options.litdouble = value.litdouble;
      hebergement.options.litsimple = value.litsimple;
      hebergement.options.spa = value.spa;
      hebergement.options.forme = value.forme;
      hebergement.options.navette = value.navette;

      const activity = metro().activity.open({
        type: 'square',
        overlayColor: '#fff',
        overlayAlpha: 0.8
      });

      this.save().then((liens) => {
        console.log('liens');
        console.log(liens);
        if (hebergement.images) {
          hebergement.images = hebergement.images.concat(liens);
        } else {
          hebergement.images = liens;
        }
        const db = firebase.firestore();
        db.collection('hebergements-trap').doc(hebergement.id).set(JSON.parse(JSON.stringify(hebergement))).then(() => {
          console.log('TERMINEEE !!!');
          const utilisateur = hebergement.prestataire;
          const keys = Object.keys(hebergement.options);
          if (utilisateur.options) {

          } else {
            utilisateur.options = new Object() as any;
          }

          keys.forEach((key) => {
            // utilisateur.options[key] = utilisateur.options[key] || hebergement.options[key];
          });

          if (!utilisateur.prixMin) {
            utilisateur.prixMin = 1000000;
          }

          utilisateur.prixMin = Math.min(utilisateur.prixMin, hebergement.nuitee);

          db.collection('utilisateurs-trap').doc(utilisateur.id).update({
            prixMin: utilisateur.prixMin
          }).then(() => {
            metro().activity.close(activity);
            this.router.navigate(['offres', 'hebergement']);
          });
        }).catch((e) => {
          metro().activity.close(activity);
        });
      });

    } else {
      alert('Veuillez sélectionner un prestataire !');
    }
  }

  uploadFile(event: any) {
    console.log(event.target.files);

    this.fichiers = event.target.files;
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < this.fichiers.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const resultat = e.target.result;
          console.log('resultat ' + i);
          console.log(resultat);
          this.images.push(resultat);
        };
        reader.readAsDataURL(event.target.files[i]);
      }

    }
  }

  save(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (this.fichiers) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.fichiers.length; i++) {
          const fichier = this.fichiers[i];
          const storageRef = firebase.storage().ref('sejours/' + Math.floor(Math.random() * 1000000) + fichier.name);
          const task = storageRef.put(this.fichiers[i]);
          task.then((data) => {
            console.log('data');
            console.log(data);
            const imageUrl = storageRef.getDownloadURL().then((url) => {
              this.liens.push(url);
              console.log('liens');
              console.log(this.liens);
              if (this.liens.length === this.fichiers.length) {
                resolve(this.liens);
              }
            });
          });
        }
      } else {
        resolve([]);
      }

    });
  }

  voirPrestataire(prestataire: Utilisateur) {
    this.router.navigate(['offres', 'hebergement', 'prestataire', prestataire.id]);
  }
}
