<div class="page-sejour">
    <div class="entete" *ngIf="sejour">

        <div class="entete__background" [ngStyle]="{'background-image': 'url('+ sejour.images[0] +')'}">
        </div>
        <div class="entete__contenu">

            <div style="margin-left: -15px; margin-bottom: -20px;">
                <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                    <li class="page-item"><a routerLink="accueil" class="page-link">{{'Accueil' | traduction}}</a></li>
                    <li class="page-item"><a (click)="goToAll()" class="page-link">{{'Séjours' | traduction}}</a></li>
                    <li class="page-item" *ngIf="sejour"><a class="page-link">{{sejour.titre}}</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="cell-lg-9">
                    <div class="titre">
                        {{sejour.titre}}
                    </div>
                    <div class="muanza-description">
                        <small>
                            <ng-container *ngIf="sejour.dateDebut && sejour.dateFin">
                                {{'Du' | traduction}} {{sejour.dateDebut | date: 'yyyy-MM-dd'}}
                                {{'au' | traduction}} {{sejour.dateFin | date: 'yyyy-MM-dd'}},
                            </ng-container>

                            {{sejour.ville}}, {{sejour.prixUnitaire}} {{'FCFA par personne' | traduction}}
                        </small>
                    </div>
                    <div class="showOnMObile" style="margin-top: 10px;">
                        <button (click)="showFormReservation()" class="button primary small"
                            style="background-color: rgb(48, 164, 221);">
                            {{'Réserver' | traduction}}
                        </button>
                    </div>
                </div>
                <div class="cell-lg-3" style="text-align: right;">
                    <button class="button primary" style="background-color: rgb(48, 164, 221);margin-left: 10px;"
                        (click)="modifier(sejour)">
                        Modifier
                    </button>
                    <button class="button alert" style="margin-left: 10px;" (click)="supprimer(sejour)">
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="contenu" *ngIf="sejour" style="padding-top: 0vh">
        <div class="">
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <div *ngIf="formReservationShowed" class="row no-gap shadow-2 muanza-box-reservation" style="">
                    <div class="cell-lg-8 bordure-bleu-debut">
                        <input formControlName="personnes" type="number" data-role="input"
                            [attr.data-prepend]="'Nombre de personnes' | traduction">
                    </div>
                    <div class="cell-lg-4 bordure-bleu-fin">
                        <button class="button" style="width: 100%; background-color: rgb(48, 164, 221); color: #fff;">
                            {{'Réserver' | traduction}}
                        </button>
                    </div>
                    <div class="showOnMObile mobile-recherche-fermer cell-lg-2">
                        <a (click)="showFormReservation()" class="button dark"
                            style="color: #fff;  width: 100%;">Fermer</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contenu">
        <div class="row">
            <div *ngIf="sejour.images[0]" class="cell-lg-7">
                <div class="trap-image-big">
                    <ng-container *ngFor="let image of sejour.images; let i = index">

                        <div *ngIf="i === indexImages" class="trap-image-big animer"
                            [ngStyle]="{'background-image': 'url(' + sejour.images[i] +')'}">

                        </div>

                    </ng-container>
                </div>
                <div class="row" style="margin-top: 5px;">
                    <div (click)="choisir(i)" class="cell-lg-1 cell-3"
                        *ngFor="let image of sejour.images; let i = index">
                        <div [ngClass]="{'actuelle': i === indexImages}"
                            style="height: 60px; cursor: pointer; background-size: cover; background-position: center center;"
                            [ngStyle]="{'background-image': 'url(' + image +')'}">

                        </div>
                    </div>
                </div>
            </div>
            <div class="cell-lg-5">
                <div class="text-leader2 sejour-suite" style="">
                    <div [innerHTML]="sejour.description" class="text-leader2">
                    </div>
                    <div class="box-details" *ngIf="sejour.pack.hebergement" style="">
                        <div class="box-details-titre">Hébergements</div>
                        <div style="margin-top: 5px;">
                            {{sejour.pack.hebergement.prestataire.nom}}
                        </div>
                        <div>
                            {{sejour.pack.hebergement.titre}}
                        </div>
                    </div>
                    <div class="box-details" *ngIf="sejour.pack.divertissements" style="line-height: 1.15;">
                        <div>
                            <div class="box-details-titre">Divertissements</div>
                            <ul style="padding:0; margin: 0; margin-top: 5px;">
                                <li *ngFor="let d of sejour.pack.divertissements">
                                    {{d.titre}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height: 20vmin;"></div>
</div>