<div style="background-color: rgb(48, 164, 221);">
    <div class="titre fg-white">
        S'inscrire à notre Newsletter
    </div>
    <div class="formulaire">
        <input class="trap-input hideOnMObile shadow-1">
        <input style="text-align: center;" placeholder="Votre addresse mail ici pour vous abonner à la Newsletter" class="showOnMObile" data-role="materialinput">

        <div>
            <button class="button large muanza">
                S'inscrire
            </button>
        </div>
    </div>
</div>