<div class="page-creation">
    <div class="row">
        <div class="cell-lg-8">
            <div class="cadre">
                <div class="titre">
                    <ng-container *ngIf="nouveau">
                        Nouvelle offre de divertissement
                    </ng-container>
                    <ng-container *ngIf="!nouveau">
                        {{divertissement.titre}}
                    </ng-container>

                </div>
                <div style="margin-bottom: 20px; font-weight: 100; font-size: 0.9em;">
                    <ng-container *ngIf="!divertissement">
                        Création d'une nouvelle offre de divertissement
                    </ng-container>
                    <ng-container *ngIf="divertissement">
                        Modifier l'offre de divertissement
                    </ng-container>
                </div>
                <div class="formulaire">
                    <div>
                        <div>
                            <input [(ngModel)]="divertissement.titre" data-role="input" data-prepend="Titre">
                        </div>
                        <div>
                            <input [(ngModel)]="divertissement.titreENG" data-role="input" data-prepend="Titre en anglais">
                        </div>
                        <div class="type-divertissement">
                            <span>
                                <input type="radio" [(ngModel)]="type" value="restaurant" data-role="radio"
                                    data-caption="Restaurant">
                            </span>
                            <span>
                                <input type="radio" [(ngModel)]="type" value="attraction" data-role="radio"
                                    data-caption="Attraction">
                            </span>
                            <span>
                                <input type="radio" [(ngModel)]="type" value="evenement" data-role="radio"
                                    data-caption="Evènement">
                            </span>
                        </div>
                        <div *ngIf="type === 'evenement'" class="row">
                            <div class="cell-lg-6">
                                <label>
                                    Date
                                </label>
                                <input *ngIf="!nouveau" class="input" [(ngModel)]="dateDiver" type="date">
                                <input *ngIf="nouveau" class="input" [(ngModel)]="divertissement.date" type="date">
                            </div>
                            <div class="cell-lg-6">
                                <label>
                                    Date fin s'il y'en a
                                </label>
                                <input *ngIf="!nouveau" class="input" [(ngModel)]="dateDiverFin" type="date">
                                <input *ngIf="nouveau" class="input" [(ngModel)]="divertissement.dateFin" type="date">
                            </div>
                        </div>
                        <div *ngIf="type !== 'restaurant'">
                            <input data-role="input" [(ngModel)]="divertissement.prix" type="number" data-prepend="Prix">
                        </div>
                        <div>
                            <input data-role="input" [(ngModel)]="divertissement.descriptionSuccincte" data-prepend="Description succincte">
                        </div>
                        <div>
                            <input data-role="input" [(ngModel)]="divertissement.descriptionSuccincteENG" data-prepend="Description succincte en anglais">
                        </div>
                        <div>
                            <textarea data-role="textarea" [(ngModel)]="divertissement.description" data-prepend="Description"></textarea>
                        </div>
                        <div>
                            <textarea data-role="textarea" [(ngModel)]="divertissement.descriptionENG" data-prepend="Description en anglais"></textarea>
                        </div>
                        <div>
                            <div *ngIf="villes.length > 0 && divertissement">
                                <div *ngIf="divertissement.ville">
                                    <select data-role="select" [(ngModel)]="divertissement.ville" data-prepend="Ville">
                                        <option *ngFor="let v of villes" [ngValue]="v.nom"
                                            [selected]="divertissement.ville===v.nom">
                                            {{v.nom}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="!divertissement.ville">
                                    <select data-role="select" [(ngModel)]="divertissement.ville" data-prepend="Ville">
                                        <option *ngFor="let v of villes" [ngValue]="v.nom"
                                            [selected]="divertissement.ville===v.nom">
                                            {{v.nom}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="villes.length > 0 && !divertissement">
                                <select data-role="select" [(ngModel)]="divertissement.ville" data-prepend="Ville">
                                    <option *ngFor="let v of villes" [ngValue]="v.nom">
                                        {{v.nom}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <input data-role="input" [(ngModel)]="divertissement.lieu" data-prepend="Adresse">
                        </div>
                        <div>
                            <input data-role="input" [(ngModel)]="divertissement.tel" data-prepend="Tel">
                        </div>

                        <div class="row">
                            <div class="cell-lg-6">
                                <input data-role="input" [(ngModel)]="divertissement.latitude" type="number" data-prepend="Latitude">
                            </div>
                            <div class="cell-lg-6">
                                <input data-role="input" [(ngModel)]="divertissement.longitude" type="number" data-prepend="Longitude">
                            </div>
                        </div>
                        <div>
                            <input (change)="uploadFile($event)" data-role="file" type="file" multiple data-prepend="Images">
                        </div>
                        <div>
                            <div class="row">
                                <ng-container *ngIf="divertissement">

                                    <div *ngFor="let image of divertissement.images" class="cell-lg-2">
                                        <img [src]="image" style="width: 100%;">
                                        <div style="margin-top: 5px;">
                                            <button (click)="retirerImage(image)" class="button" style="width: 100%;">
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngFor="let img of images" class="cell-lg-2">
                                    <img [src]="img" style="width: 100%;">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell-lg-6">
                                <button (click)="enregistrer()" style="background-color: rgb(48, 164, 221);" class="button warning">
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cell-lg-4">
            <div class="apercu">
                <div class="apercu-titre">
                    Aperçu du divertissement
                </div>
                <div *ngIf="divertissement">
                    <app-display-divertissement [divertissement]="divertissement">

                    </app-display-divertissement>
                </div>
            </div>
        </div>
    </div>
</div>