<div class="page-sejour">
    <div class="entete2" style="">
        <div class="row">
            <div class="cell-lg-5">
                <div class="titre">
                    Taux de conversion
                </div>
                <div class="sous-titre text-leader2">
                    Tous les taux de conversions ici
                </div>
            </div>
        </div>
    </div>
    <div class="contenu2">
        <div class="row">
            <div class="col-lg-6">
                <div style="margin-bottom: 20px;">
                    <input type="number" [(ngModel)]="taux.eur" data-role="input" data-prepend="Taux de conversion EURO">
                </div>
                <div style="margin-bottom: 20px;">
                    <input type="number" [(ngModel)]="taux.usd" data-role="input" data-prepend="Taux de conversion USD">
                </div>
                <div>
                    <button (click)="saveTaux()" class="button ">
                        Enregistrer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>