<div *ngIf="sejour" class="sejour" (click)="ouvrir(sejour.id)">
    <div class="sejour__conteneur">
        <div class="sejour__image" [ngStyle]="{'background-image': 'url(' + sejour.images[0] +')'}">

        </div>
        <div class="muanza-details">
        </div>
    </div>
    <div class="sejour__description">
        <div class="row">
            <div class="cell-12" style="overflow: hidden;">
                <b class="petit-titre">
                    {{sejour.titre}},
                </b>
                <span class="sejour__calendrier">
                    {{'du' | traduction}} 
                    <span *ngIf="sejour.dateDebut">
                        {{sejour.dateDebut | date: 'yyyy-MM-dd'}}
                    </span>
                <span *ngIf="!sejour.dateDebut">
                        28/08/2020
                    </span> {{'au' | traduction}}
                <span *ngIf="sejour.dateFin">
                        {{sejour.dateFin | date: 'yyyy-MM-dd'}}
                    </span>
                <span *ngIf="!sejour.dateFin">
                        30/08/2020
                    </span>
                </span>
            </div>
            <!---
            <div class="cell-6" style="overflow: hidden;">
                <div style="display: none;" class="sejour__notation orange" [innerHTML]="notationToStars(sejour.notation)">

                </div>
            </div>
        -->
        </div>
        <div class="sejour__prix">
            {{sejour.prixUnitaire | conversion | currency:devise:'symbol':'1.2-2':fuseau}} / {{'Personne' | traduction}}
        </div>
        <div *ngIf="sejour.ville" style="padding-top: 5px;">
            {{sejour.ville}}
        </div>
    </div>
</div>