import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';
import { Agence } from 'src/app/models/agence.model';
import { Gare } from 'src/app/models/gare.model';
import { Ville } from 'src/app/models/ville.model';
declare const metro: any;

@Component({
  selector: 'app-gare-edit',
  templateUrl: './gare-edit.component.html',
  styleUrls: ['./gare-edit.component.scss']
})
export class GareEditComponent implements OnInit {

  gare: Gare;
  form: FormGroup;
  fichiers: FileList;
  images = new Array<Blob>();
  liens = new Array<string>();
  agences = new Array<Agence>();
  villes = new Array<Ville>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVilles();
    this.getAgences().then((agences) => {
      this.agences = agences;
      this.route.paramMap.subscribe((paramMap) => {
        const id = paramMap.get('id');
        if (id) {
          const db = firebase.firestore();
          db.collection('gares-trap').doc(id).get().then((resultat) => {
            const gare = resultat.data() as Gare;
            this.gare = gare;
            this.agences.forEach((a) => {
              if (a.id === this.gare.agence.id) {
                this.gare.agence = a;
              }
            });
            this.initForm();
          }).catch((e) => {
          });
        }
      });
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      nom: [this.gare ? this.gare.nom : '', Validators.required],
      lieu: [this.gare ? this.gare.lieu : '', Validators.required],
      agence: [this.gare ? this.gare.agence : '', Validators.required],
      ville: [this.gare ? this.gare.ville  : '', Validators.required],
    });

  }

  getAgences(): Promise<Array<Agence>> {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      const trajets = new Array<Agence>();
      db.collection('agences-trap').get().then((resultats) => {
        resultats.forEach((resultat) => {
          const trajet = resultat.data() as Agence;
          trajets.push(trajet);
        });
        resolve(trajets);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  getVilles() {
    this.villes = new Array<Ville>();
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('ville-trap').get().then((resultats) => {
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
      resultats.forEach((resultat) => {
        const ville = resultat.data() as Ville;
        this.villes.push(ville);
      });
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

  onSubmitForm() {
    const value = this.form.value;
    let gare = new Gare();

    if (this.gare) {
      gare = this.gare;
    }

    gare.nom = value.nom;
    gare.ville = value.ville;
    gare.agence = value.agence;
    gare.lieu = value.lieu;

    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    this.saveImage().then((liens) => {
      console.log('liens');
      console.log(liens);
      if (liens.length > 0) {
        if (gare.images) {
          gare.images = gare.images.concat(liens);
        } else {
          gare.images = liens;
        }
      }

      const db = firebase.firestore();
      db.collection('gares-trap').doc(gare.id).set(JSON.parse(JSON.stringify(gare))).then(() => {
        metro().activity.close(activity);
        this.router.navigate(['offres', 'transport', 'gare']);
      }).catch((e) => {
        metro().activity.close(activity);
      });
    });
  }

  uploadFile(event: any) {
    console.log(event.target.files);

    this.fichiers = event.target.files;
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < this.fichiers.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const resultat = e.target.result;
          console.log('resultat ' + i);
          console.log(resultat);
          this.images.push(resultat);
        };
        reader.readAsDataURL(event.target.files[i]);
      }

    }
  }

  saveImage(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (this.fichiers) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.fichiers.length; i++) {
          const fichier = this.fichiers[i];
          const storageRef = firebase.storage().ref('gares/' + Math.floor(Math.random() * 1000000) + fichier.name);
          const task = storageRef.put(this.fichiers[i]);
          task.then((data) => {
            console.log('data');
            console.log(data);
            const imageUrl = storageRef.getDownloadURL().then((url) => {
              this.liens.push(url);
              console.log('liens');
              console.log(this.liens);
              if (this.liens.length === this.fichiers.length) {
                resolve(this.liens);
              }
            });
          });
        }
      } else {
        resolve([]);
      }

    });
  }

  retirerImage(image) {
    const oui = confirm('Etes-vous sûr de vouloir supprimer cette image ?');
    if (oui) {
      const images = [];
      this.gare.images.forEach((img) => {
        if (image !== img) {
          images.push(img);
        }
      });
      this.gare.images = images;
    }
  }


}
