<div class="page">
    <div class="entete">
        <div *ngIf="!depart" class="titre">
            Nouveau départ
        </div>
        <div *ngIf="depart" class="titre">
            Départ
        </div>
    </div>
    <div class="contenu">
        <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
            <div>
                <div *ngIf="agences.length > 0 && depart">
                    <select data-role="select" data-prepend="Agence" formControlName="agence">
                        <option *ngFor="let agence of agences" [ngValue]="agence">
                            {{agence.nom}}
                        </option>
                    </select>
                </div>
                <div *ngIf="agences.length > 0 && !depart">
                    <select data-role="select" data-prepend="Agence" formControlName="agence">
                        <option *ngFor="let agence of agences" [ngValue]="agence">
                            {{agence.nom}}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <div *ngIf="trajets.length > 0 && depart">
                    <select data-role="select" data-prepend="Trajet" formControlName="trajet">
                        <option *ngFor="let trajet of trajets" [ngValue]="trajet">
                            {{trajet.villeDepart}} - {{trajet.villeArrivee}}
                        </option>
                    </select>
                </div>
                <div *ngIf="trajets.length > 0 && !depart">
                    <select data-role="select" data-prepend="Trajet" formControlName="trajet">
                        <option *ngFor="let trajet of trajets" [ngValue]="trajet">
                            {{trajet.villeDepart}} - {{trajet.villeArrivee}}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <input formControlName="modele" data-role="input" data-prepend="Modèle du véhicule">
            </div>
            <div>
                <input formControlName="heures" data-role="input" data-prepend="Heures">
                <small>
                    Séparez les heures par des points virgules (;)
                </small>
            </div>
            <div>
                <input formControlName="vip" type="checkbox" value="muanza" data-role="checkbox"
                    data-caption="Service VIP">
            </div>
            <div>
                <input formControlName="prix" data-role="input" type="number" data-prepend="Coût du trajet">
            </div>
            <div>
                <input formControlName="prixAR" data-role="input" type="number"
                    data-prepend="Coût du trajet Aller Retour">
            </div>
            <div>
                <button class="button">Enregistrer</button>
            </div>
        </form>
    </div>
</div>