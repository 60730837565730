import { Component, OnInit } from '@angular/core';
import { Agence } from 'src/app/models/agence.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
declare const metro: any;

@Component({
  selector: 'app-agence-edit',
  templateUrl: './agence-edit.component.html',
  styleUrls: ['./agence-edit.component.scss']
})
export class AgenceEditComponent implements OnInit {

  agence: Agence;
  form: FormGroup;
  fichiers: FileList;
  images = new Array<Blob>();
  liens = new Array<string>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.route.paramMap.subscribe((paramMap) => {
      const id = paramMap.get('id');
      const db = firebase.firestore();
      db.collection('agences-trap').doc(id).get().then((resultat) => {
        const agence = resultat.data() as Agence;
        this.agence = agence;
        this.initForm();
      }).catch((e) => {
      });
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      nom: [this.agence ? this.agence.nom : '', Validators.required],
      bus: [this.agence ? Boolean(this.agence.bus) : false]
    });

  }

  onSubmitForm() {
    const value = this.form.value;
    let agence = new Agence();

    if (this.agence) {
      agence = this.agence;
    }

    agence.nom = value.nom;
    if (value.bus) {
      agence.bus = true;
    } else {
      agence.bus = false;
    }

    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    this.saveImage().then((liens) => {
      console.log('liens');
      console.log(liens);
      if (liens.length > 0) {
        agence.logo = liens[0];
      }

      const db = firebase.firestore();
      db.collection('agences-trap').doc(agence.id).set(JSON.parse(JSON.stringify(agence))).then(() => {
        metro().activity.close(activity);
        this.router.navigate(['offres', 'transport', 'agence']);
      }).catch((e) => {
        metro().activity.close(activity);
      });
    });
  }

  uploadFile(event: any) {
    console.log(event.target.files);

    this.fichiers = event.target.files;
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < 1; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const resultat = e.target.result;
          console.log('resultat ' + i);
          console.log(resultat);
          this.images.push(resultat);
        };
        reader.readAsDataURL(event.target.files[i]);
      }

    }
  }

  saveImage(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (this.fichiers) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.fichiers.length; i++) {
          const fichier = this.fichiers[i];
          const storageRef = firebase.storage().ref('agences/' + Math.floor(Math.random() * 1000000) + fichier.name);
          const task = storageRef.put(this.fichiers[i]);
          task.then((data) => {
            console.log('data');
            console.log(data);
            const imageUrl = storageRef.getDownloadURL().then((url) => {
              this.liens.push(url);
              console.log('liens');
              console.log(this.liens);
              if (this.liens.length === this.fichiers.length) {
                resolve(this.liens);
              }
            });
          });
        }
      } else {
        resolve([]);
      }

    });
  }


}
