<div class="trait">

</div>
<div class="titre">
    <span>
        Nos offres de transports
    </span>
</div>

<div class="transports">
    <div class="row">
        <div class="cell-lg-3" *ngFor="let transport of transports; let index = index;">
            <div *ngIf="index < 4">
                <app-display-transport [transport]="transport"></app-display-transport>
            </div>
        </div>
    </div>
</div>