<div class="page-creation">
    <div class="row">
        <div class="cell-lg-8">
            <div class="cadre">
                <div class="titre">
                    <ng-container *ngIf="nouveau">
                        Création d'une nouvelle activité pour le divertissement
                    </ng-container>
                    <ng-container *ngIf="!nouveau">
                        Modifier l'activité pour le divertissement
                    </ng-container>
                </div>
                <div style="margin-bottom: 10px; font-weight: 100; font-size: 0.9em;">
                    <ng-container *ngIf="divertissement">
                        {{divertissement.titre}}
                    </ng-container>
                </div>
                <div style="font-size: 1em;margin-bottom: 10px; ">
                    <ng-container *ngIf="!divertissementItem">

                    </ng-container>
                    <ng-container *ngIf="divertissementItem">
                        {{divertissementItem.titre}}
                    </ng-container>
                </div>
                <div class="formulaire">
                    <div>
                        <div>
                            <input [(ngModel)]="divertissementItem.titre" data-role="input" data-prepend="Titre">
                        </div>
                        <div>
                            <input [(ngModel)]="divertissementItem.titreENG" data-role="input" data-prepend="Titre en anglais">
                        </div>
                        <div>
                            <input data-role="input" [(ngModel)]="divertissementItem.descriptionSuccincte" data-prepend="Description succincte">
                        </div>
                        <div>
                            <input data-role="input" [(ngModel)]="divertissementItem.descriptionSuccincteENG" data-prepend="Description succincte en anglais">
                        </div>
                        <div>
                            <textarea data-role="textarea" [(ngModel)]="divertissementItem.description" data-prepend="Description"></textarea>
                        </div>
                        <div>
                            <textarea data-role="textarea" [(ngModel)]="divertissementItem.descriptionENG" data-prepend="Description en anglais"></textarea>
                        </div>
                        <div>
                            <input data-role="input" type="number" [(ngModel)]="divertissementItem.prix" data-prepend="Prix">
                        </div>
                        <div>
                            <input (change)="uploadFile($event)" data-role="file" type="file" multiple data-prepend="Images">
                        </div>
                        <div>
                            <div class="row">
                                <ng-container *ngIf="divertissementItem">

                                    <div *ngFor="let image of divertissementItem.images" class="cell-lg-2">
                                        <img [src]="image" style="width: 100%;">
                                        <div style="margin-top: 5px;">
                                            <button (click)="retirerImage(image)" class="button" style="width: 100%;">
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngFor="let img of images" class="cell-lg-2">
                                    <img [src]="img" style="width: 100%;">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell-lg-6">
                                <button (click)="enregistrer()" style="background-color: rgb(48, 164, 221);" class="button warning">
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cell-lg-4">
            <div class="apercu">
                <div class="apercu-titre">
                    Aperçu du divertissement
                </div>
                <div *ngIf="divertissement">
                    <app-display-divertissement-item [divertissementItem]="divertissementItem">

                    </app-display-divertissement-item>
                </div>
            </div>
        </div>
    </div>
</div>