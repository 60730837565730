import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Reservation } from 'src/app/models/reservation.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { Trajet } from 'src/app/models/trajet.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Paiement } from 'src/app/models/paiement.model';
import { Hebergement } from 'src/app/models/hebergement.model';
import { formatNumber } from '@angular/common';
import { EmailService } from 'src/app/services/email.service';
declare var CinetPay: any;

declare const metro: any;

@Component({
  selector: 'app-reservation-recap',
  templateUrl: './reservation-recap.component.html',
  styleUrls: ['./reservation-recap.component.scss']
})
export class ReservationRecapComponent implements OnInit {

  paiement: Paiement;
  days = 0;
  form: FormGroup;
  mailEnvoyee = false;
  total = 0;
  mailBienEnvoyee = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private emailService: EmailService,
    @Inject(LOCALE_ID) public locale: string,

  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const id = paramMap.get('id');
      this.getReservation(id);
      this.initForm();
    });
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      tel: ['', Validators.required],
      numero: ['', Validators.required],
    });
  }

  getReservation(id) {
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });
    const db = firebase.firestore();
    db.collection('paiement-trap').doc(id).get().then((resultat) => {
      console.log('TERMINEEE !!!');
      this.paiement = resultat.data() as Paiement;
      if (this.paiement.mailEnvoyee) {
        this.mailEnvoyee = true;
      }
      metro().activity.close(activity);
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

  notationToStars(notation: number) {
    notation = Math.floor(notation);
    let stars = '';
    for (let i = 0; i < notation; i++) {
      stars = stars + '<span class="mif-star-full" style="color: rgb(48, 164, 221);"></span>';
    }
    for (let j = 0; j < 5 - notation; j++) {
      stars = stars + '<span class="mif-star-empty" style="color: rgb(48, 164, 221);"></span>';
    }
    return stars;
  }

  toDate(str) {
    return new Date(str);
  }

  panier() {
    this.router.navigate(['panier']);
  }

  verifier() {
    let nom: string;
    let prenom: string;
    let email: string;
    let tel: string;
    if (this.paiement.reservations[0]) {
      const reservation = this.paiement.reservations[0];
      nom = reservation.responsable.nom;
      prenom = reservation.responsable.prenom;
      email = reservation.responsable.email;
      tel = '6' + reservation.responsable.tel;
    }

    console.log('CinetPay');
    console.log(CinetPay);
    CinetPay.setConfig({
      apikey: '14805067945e0b6eb3374f47.48751476',
      site_id: '962769',
      mode: 'PRODUCTION',
      notify_url: 'https://mondomaine.com/notify/',
      device_id: '',
      type: ''
    });

    const data = {
      transaction_id: this.paiement.id,
      amount: this.paiement.total,
      currency: 'XAF',
      channels: 'ALL',
      description: 'YOUR_PAYMENT_DESCRIPTION',
      customer_name: nom ? nom : '', // Le nom du client
      customer_surname: prenom ? prenom : '', // Le prenom du client
      customer_email: email ? email : '', // l'email du client
      customer_phone_number: tel ? tel : '', // l'email du client
      customer_address: 'BP 0024', // addresse du client
      customer_city: 'Antananarivo', // La ville du client
      customer_country: 'CM', // le code ISO du pays
      customer_state: 'CM', // le code ISO l'état
      customer_zip_code: '06510', // code postal
    };

    console.log('CinetPay');
    console.log(CinetPay);
    // cinetPay.initCheckout();
    console.log('getCheckout');
    CinetPay.getCheckout(data);
    CinetPay.waitResponse((d: any) => {
      console.log('Réponse du serveur');
      console.log(d);
    });

    CinetPay.onError((d): void => {
      console.log(d);
      if (d) {
        // CinetPay.closeCheckout();
        alert('Paiement effectué');
        this.paiement.statut = 4;
        const db = firebase.firestore();
        db.collection('paiement-trap').doc(this.paiement.id).set(JSON.parse(JSON.stringify(this.paiement))).then((resultat) => {
          window.location.reload();
        }).catch((e) => {
          window.location.reload();
        });
      }
    });

  }

  envoyerMailConfirmation() {
    this.emailService.envoyerMailConfirmation(this.paiement).then(() => {
      this.mailBienEnvoyee = true;
    });
  }

  description(trajet: Trajet) {
    if (trajet) {
      if (trajet.villeArrivee === trajet.villeDepart) {
        return 'Location de voiture : ' + trajet.villeArrivee;
      } else {
        return trajet.villeDepart + ' - ' + trajet.villeArrivee;
      }
    } else {
      return '';
    }
  }

  duree(reservation: Reservation) {
    const dateFin = reservation.dateFin;
    const date = reservation.dateDebut;

    if (dateFin && date) {
      const diff = new Date(dateFin).getTime() - new Date(date).getTime();

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));

      return days;
    } else {
      return 1;
    }
  }

  async estPayee(ev: any) {
    console.log('estPayee');
    console.log(ev);
    console.log(ev.target.checked);
    const payee = ev.target.checked;
    this.paiement.statut = payee ? 4 : 1;
    const db = firebase.firestore();
    for (let index = 0; index < this.paiement.reservations.length; index++) {
      const reservation = this.paiement.reservations[index];
      reservation.payee = payee;
      await db.collection('reservation-trap').doc(reservation.id).set(JSON.parse(JSON.stringify(reservation)));
      console.log("reservation " + reservation.id + " payéé")
    }
    await db.collection('paiement-trap').doc(this.paiement.id).set(JSON.parse(JSON.stringify(this.paiement)));
    console.log("paiement " + this.paiement.id + " validé")
  }

}
