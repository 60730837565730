<div class="page">
    <div class="entete">
        <div *ngIf="!gare" class="titre">
            Ajoutez une nouvelle gare
        </div>
        <div *ngIf="gare" class="titre">
            Modifier la gare : {{gare.nom}}
        </div>
    </div>
    <div class="contenu">
        <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
            <div *ngIf="agences.length > 0 && !gare">
                <select data-role="select" formControlName="agence" data-prepend="Agence">
                    <option *ngFor="let agence of agences" [ngValue]="agence">
                        {{agence.nom}}
                    </option>
                </select>
            </div>
            <div *ngIf="agences.length > 0 && gare">
                <select data-role="select" formControlName="agence" data-prepend="Agence">
                    <option *ngFor="let agence of agences" [ngValue]="agence">
                        {{agence.nom}}
                    </option>
                </select>
            </div>
            <div *ngIf="villes.length > 0 && !gare">
                <select data-role="select" formControlName="ville" data-prepend="Ville d'arrivée">
                    <option *ngFor="let v of villes" [value]="v.nom">
                        {{v.nom}}
                    </option>
                </select>
            </div>
            <div *ngIf="villes.length > 0 && gare">
                <select data-role="select" formControlName="ville" data-prepend="Ville d'arrivée">
                    <option *ngFor="let v of villes" [value]="v.nom">
                        {{v.nom}}
                    </option>
                </select>
            </div>
            <div>
                <input formControlName="nom" data-role="input" data-prepend="Nom de l'agence">
            </div>
            <div>
                <input formControlName="lieu" data-role="input" data-prepend="Localisation de l'agence">
            </div>
            <div style="margin-top: 10px;">
                <input (change)="uploadFile($event)" type="file" data-role="file" data-prepend="Images" multiple>
            </div>
            <div class="row">
                <ng-container *ngIf="gare">
                    <div class="col-lg-2" *ngFor="let image of gare.images" style="margin-top:10px; margin-bottom: 10px">
                        <img [src]="image" style="width: 100%;">
                        <div style="margin-top: 5px;">
                            <button (click)="retirerImage(image)" class="button" style="width: 100%;">
                                Supprimer
                            </button>
                        </div>
                    </div>

                </ng-container>
                <div class="col-lg-2" *ngFor="let image of images" style="margin-top:10px; margin-bottom: 10px">
                    <img [src]="image" style="width: 100%;">
                </div>
            </div>
            <div>
                <button class="button">Enregistrer</button>
            </div>
        </form>
    </div>
</div>