<div class="page" *ngIf="divertissement">
    <div class="entete">
        <div class="row">
            <div class="cell-lg-9">
                <div class="titre">
                    {{divertissement.titre}}
                </div>
                <div class="sous-titre text-leader2">
                    {{divertissement.description}}
                </div>
                <div>
                    Le
                    <b>{{divertissement.date | date: 'yyyy-MM-dd'}} </b>
                </div>
            </div>
            <div class="cell-lg-3 text-right">
                <app-admin>
                    <div class="administration">
                        <button class="button primary" style="background-color: rgb(48, 164, 221);"
                            (click)="modifier(divertissement)">
                            Modifier
                        </button>
                        <button class="button alert" style="margin-left: 10px;" (click)="supprimer(divertissement)">
                            Supprimer
                        </button>
                    </div>
                </app-admin>
            </div>
        </div>
        <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
            <div *ngIf="!passee" class="row no-gap shadow-3">
                <div class="cell-lg-8 bordure-bleu-debut">
                    <input formControlName="personnes" data-role="input" data-prepend="Personnes">
                </div>
                <div class="cell-lg-4 bordure-bleu-fin">
                    <button class="button warning" style="width: 100%; background-color: rgb(48, 164, 221);">
                        Réserver
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="contenu" style="padding-top: 5vh">
        <div class="row">
            <div *ngFor="let image of divertissement.images" class="cell-lg-4">
                <div class="trap-image" [ngStyle]="{'background-image': 'url(' + image +')'}">

                </div>
            </div>
        </div>
    </div>
</div>