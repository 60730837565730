<div class="trapyourtrip">
    <app-menu-haut></app-menu-haut>
    <div>
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>

<div class="connexion" *ngIf="!isUser">

    <div class="connexion-contenu">
        <div class="logo">
            <img src="../assets/img/Trap Your Trip Logo 2.png" style="height: 80px;" alt="">
        </div>
        <div class="formulaire">
            <div class="formulaire-titre">
                Veuillez vous connecter
            </div>
            <div class="champ">
                <input data-prepend="login" [(ngModel)]="login" data-role="input" type="text">
            </div>
            <div class="champ">
                <input data-prepend="passe" type="password" [(ngModel)]="passe" data-role="input">
            </div>
            <div class="champ">
                <button (click)="connexion()" class="button warning" style="background-color: rgb(48, 164, 221);width: 100%;">
                    Connexion
                </button>
            </div>
        </div>
    </div>

</div>