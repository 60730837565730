import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';
import { DivertissementItem } from 'src/app/models/divertissement.item.model';
import { Divertissement } from 'src/app/models/divertissement.model';
import { Ville } from 'src/app/models/ville.model';
declare const metro: any;

@Component({
  selector: 'app-divertissement-item-edit',
  templateUrl: './divertissement-item-edit.component.html',
  styleUrls: ['./divertissement-item-edit.component.scss']
})
export class DivertissementItemEditComponent implements OnInit {

  form: FormGroup;
  fichiers: FileList;
  images = new Array<Blob>();
  liens = new Array<string>();
  divertissement = new Divertissement();
  divertissementItem: DivertissementItem;
  nouveau = true;
  type = 'restaurant';
  dateDiver = new Date().toISOString().split('T')[0];
  dateDiverFin: string;
  villes = new Array<Ville>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getVilles();
    this.route.paramMap.subscribe((paramMap) => {
      const iddivertissement = paramMap.get('iddivertissement');
      const id = paramMap.get('id');
      console.log('iddivertissement');
      console.log(iddivertissement);
      if (iddivertissement) {
        this.getDivertissement(iddivertissement).then((divertissement) => {
          this.divertissement = divertissement;
          this.divertissementItem = new DivertissementItem(this.divertissement);
        });
      }
      if (id) {
        this.getItem(id).then(() => {
          this.nouveau = false;
          this.divertissement = this.divertissementItem.divertissement;
        });
      }
    });
  }

  getVilles() {
    this.villes = new Array<Ville>();
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('ville-trap').get().then((resultats) => {
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
      resultats.forEach((resultat) => {
        const ville = resultat.data() as Ville;
        this.villes.push(ville);
      });
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

  enregistrer() {
    console.log('enregistrer');
    console.log('this.divertissementItem');

    console.log(this.divertissementItem);

    this.saveImages().then((liens) => {
      this.images = [];
      if (this.divertissementItem.images) {
        this.divertissementItem.images = this.divertissementItem.images.concat(liens);
      } else {
        this.divertissementItem.images = liens;
      }
      console.log(this.divertissementItem);
      this.save();
    });
  }

  save() {
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });
    const db = firebase.firestore();
    db.collection('divertissements-item-trap').doc(this.divertissementItem.id)
      .set(JSON.parse(JSON.stringify(this.divertissementItem))).then((resultat) => {
        console.log('TERMINEEE !!!');
        metro().activity.close(activity);
        if (this.divertissement.date) {
          this.router.navigate(['offres', 'divertissement', 'evenements']);
        } else if (this.divertissement.restaurant) {
          this.router.navigate(['offres', 'divertissement', 'restaurants']);
        } else {
          this.router.navigate(['offres', 'divertissement', 'loisirs', 'view', this.divertissement.id]);
        }
      }).catch((e) => {
        metro().activity.close(activity);
      });
  }

  getItem(id: string) {
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection('divertissements-item-trap').doc(id).get().then((resultat) => {
        const divertissementItem = resultat.data() as DivertissementItem;
        this.divertissementItem = divertissementItem;
        console.log('TERMINEEE !!!');
        console.log(this.divertissementItem);
        metro().activity.close(activity);
        resolve(divertissementItem);
      }).catch((e) => {
        metro().activity.close(activity);
      });
    });
  }

  toDate(date) {
    if (date) {
      const d = new Date(date);
      return d.toISOString().split('T')[0];
    }
    return null;
  }

  uploadFile(event: any) {
    console.log(event.target.files);

    this.fichiers = event.target.files;
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < this.fichiers.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const resultat = e.target.result;
          console.log('resultat ' + i);
          console.log(resultat);
          this.images.push(resultat);
        };
        reader.readAsDataURL(event.target.files[i]);
      }

    }
  }

  retirerImage(image) {
    const oui = confirm('Etes-vous sûr de vouloir supprimer cette image ?');
    if (oui) {
      const images = [];
      this.divertissementItem.images.forEach((img) => {
        if (image !== img) {
          images.push(img);
        }
      });
      this.divertissementItem.images = images;
    }
  }

  saveImages(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (this.fichiers) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.fichiers.length; i++) {
          const fichier = this.fichiers[i];
          const storageRef = firebase.storage().ref('sejours/' + Math.floor(Math.random() * 1000000) + fichier.name);
          const task = storageRef.put(this.fichiers[i]);
          task.then((data) => {
            console.log('data');
            console.log(data);
            const imageUrl = storageRef.getDownloadURL().then((url) => {
              this.liens.push(url);
              console.log('liens');
              console.log(this.liens);
              if (this.liens.length === this.fichiers.length) {
                resolve(this.liens);
              }
            });
          });
        }
      } else {
        resolve([]);
      }
    });
  }

  getDivertissement(id): Promise<Divertissement> {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection('divertissements-trap').doc(id).get().then((resultat) => {
        const div = resultat.data() as Divertissement;
        resolve(div);
      });
    });
  }

}
