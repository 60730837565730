<div *ngIf="divertissement" class="sejour" (click)="ouvrir(divertissement.id)">
    <div class="sejour__conteneur">
        <div class="sejour__image" [ngStyle]="{'background-image': 'url(' + divertissement.images[0] +')'}">

        </div>
        <div class="sejour__prix">
            <span>
                ~ {{divertissement.prix | conversion  | currency:devise:'symbol':'1.2-2':fuseau}}
            </span>
        </div>
    </div>
    <div class="row" style="font-size: 1.2em;">
        <div class="cell-8">
            <div class="sejour__description">
                {{divertissement.titre}}
            </div>
            <div>
                <b>{{divertissement.ville}}</b><span *ngIf="divertissement.ville && divertissement.lieu">,</span>
                <span>
                    {{divertissement.lieu}}
                </span>
            </div>
        </div>
        <div class="cell-4">
            <div style="padding-top: 10px; text-align: right;">
                <div class="sejour__notation orange" [innerHTML]="notationToStars(divertissement.notation)"></div>


            </div>
        </div>
    </div>
</div>