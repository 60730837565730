<div class="trait">

</div>
<div class="titre">
    <span>
        Dernières offres d'hébergement
    </span>
</div>

<div class="sejours">
    <div class="row">
        <div class="cell-lg-12">
            <div class="row">
                <div class="cell-lg-3" *ngFor="let hebergement of hebergements; let index = index;">
                    <div *ngIf="index < 8">
                        <app-display-hebergement [hebergement]="hebergement"></app-display-hebergement>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>