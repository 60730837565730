<div class="page">
    <div class="entete">
        <div class="row">
            <div class="col-lg-8">
                <div class="titre">
                    Liste des gares
                </div>
            </div>
            <div class="cell-lg-4">
                <app-admin>
                    <div class="administration">
                        <button class="button primary" style="background-color: rgb(48, 164, 221);" (click)="nouveau()">
                            Nouveau
                        </button>
                    </div>
                </app-admin>
            </div>
        </div>
    </div>
    <div class="contenu">
        <div class="gares">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Agence</th>
                        <th>Nom</th>
                        <th>Ville</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let gare of gares" style="cursor: pointer;">
                        <td>{{ gare.id }}</td>
                        <td>
                            {{ gare.agence.nom | uppercase}}
                        </td>
                        <td>{{ gare.nom}}</td>
                        <td>{{ gare.ville}}</td>
                        <td>
                            <button class="button" (click)="modifier(gare)">
                                Modifier
                            </button>
                            <button style="margin-left: 10px;" class="button alert" (click)="supprimer(gare)">
                                Supprimer
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>