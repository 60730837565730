<div style="">
<div class="trait">

</div>
<div class="titre">
    <span>
        Dernières offres de divertissements
    </span>
</div>

<div class="sejours">
    <div class="row">

        <div class="cell-lg-12">
            <div class="row">
                <div class="cell-lg-4" *ngFor="let divertissement of divertissements; let index = index;">
                    <div *ngIf="index < 6">
                        <app-display-divertissement [divertissement]="divertissement"></app-display-divertissement>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>