<div class="trait">

</div>
<div class="titre">
    <span>
        Dernières offres de séjours
    </span>
</div>

<div class="sejours">
    <div class="row">
        <div class="cell-lg-12">
            <div class="row">
                <div class="cell-lg-3" *ngFor="let sejour of sejours; let index = index;">
                    <div *ngIf="index < 8">
                        <app-display-sejour [sejour]="sejour"></app-display-sejour>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>