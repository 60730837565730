import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-taux',
  templateUrl: './taux.component.html',
  styleUrls: ['./taux.component.scss']
})
export class TauxComponent implements OnInit {

  taux: any = {
    eur: 655,
    usd: 555,
  };

  constructor() { }

  ngOnInit(): void {
    this.getTaux();
  }

  getTaux() {
    const db = firebase.firestore();
    db.collection('taux').doc('0').get().then((resultat) => {
      if (resultat.data()) {
        this.taux = resultat.data();
      }
    }).catch((e) => {

    });
  }

  saveTaux() {
    const db = firebase.firestore();
    db.collection('taux').doc('0').set(this.taux).then((resultat) => {
    }).catch((e) => {

    });
  }

}
