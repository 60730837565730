<div>
    <div style="padding: 5%; padding-top: 7%;">
        <h1>
            Conditions générales d’utilisation
        </h1>
        <div>
            <h2>
                Contenu
            </h2>
            <h3>
                Code de bonnes pratiques
            </h3>
            <p>
                Nous avons pour mission de permettre aux voyageurs de vivre des expériences en Afrique, en proposant les
                meilleurs hébergements et divertissements pour un séjour des plus confortables. Afin d'atteindre cet
                objectif, nous suivons ces bonnes pratiques :
            </p>
            <ul>
                <li>
                    Nous souhaitons que votre expérience soit optimale : nous vous proposons ainsi notre Plateforme et
                    notre
                    Service Clients en Français et en Anglais et bientôt dans plusieurs autres langues.
                </li>
                <li>
                    Nous vous permettons d'explorer le Cameroun : grâce à des d'établissements, du luxueux au
                    fonctionnel,
                    répondant à vos besoins, qu'il s'agisse d'un hôtel, d'un appartement, d'une villa... partout au
                    Cameroun
                </li>
                <li>
                    Nous mettons à votre disposition des divertissements et autres prestations
                </li>
                <li>
                    Nous pouvons faciliter le paiement de tous frais (d'accès), achat ou location d'un produit et d'un
                    service de Voyage utilisant notre service de paiement
                </li>
                <li>
                    Nous vous assistons (24h/24 et 7j/7) : nos centres de Service Clients sont là pour vous
                </li>
                <li>
                    Nous sommes à votre écoute : notre Plateforme constitue un produit pratique qui correspond à VOS
                    (celles
                    des utilisateurs) préférences
                </li>
                <li>
                    Nous vous laissons vous exprimer : nous affichons les notes recueillies par des clients qui ont
                    réellement consommé des prestations
                </li>
                <li>
                    Nous vous promettons un site Internet informatif et facile à utiliser, qui garantit les meilleurs
                    tarifs disponibles
                </li>
                <li>
                    Nous ajustons nos tarifs !
                </li>
            </ul>
        </div>
        <div>
            <h2>
                Introduction - Conditions générales
            </h2>
            <p>
                Les présentes conditions générales d’utilisation, qui sont susceptibles d’être modifiées de façon
                ponctuelle, s’appliquent à tous nos services, directement ou indirectement (fournis par nos prestataires
                partenaires) en ligne, via des appareils mobiles, par e-mail ou par téléphone. En accédant à notre site
                Internet (mobile) ou à toute autre application via une plateforme (ci-après dénommés collectivement la «
                Plateforme »), et/ou en y effectuant une réservation, vous déclarez avoir pris connaissance des
                présentes conditions générales d’utilisation et de notre charte de confidentialité, en comprendre la
                portée et les accepter.
            </p>
            <p>
                Les présentes pages, leur contenu et leur infrastructure, ainsi que le service de réservation en ligne
                (y compris la facilitation du service de paiement) fournis par nous via le présent site Internet
                appartiennent à, sont gérés par et sont fournis par TRAP YOUR TRIP S.A.R.L. Ils sont mis à votre
                disposition uniquement pour un usage personnel et non commercial (B2C), qui demeure soumis aux
                conditions générales d’utilisation établies ci-dessous. Notre relation avec les prestataires partenaires
                est régie par des conditions générales séparées qui régissent la relation commerciale (B2B) que nous
                avons avec chaque prestataire partenaire. Tout prestataire partenaire agit de façon professionnelle
                envers TRAP YOUR TRIP lorsqu'il s'agit de rendre leur produit ou service disponible sur ou via TRAP YOUR
                TRIP (à la fois dans le cadre de ses relations commerciales « B2B » et de ses relations non commerciales
                « B2C »). Veuillez noter que les prestataires partenaires peuvent détenir, déclarer applicables et
                nécessiter (l'acceptation de) leurs propres conditions générales (de prestation, d'expédition, de
                transport, d'utilisation) et règles de la maison pour l'utilisation, l'accès et la consommation des
                prestations (qui pourrait comprendre certaines exclusions et limitations de responsabilité).
            </p>
            <h2>
                Définitions
            </h2>
            <p>
                « TRAP YOUR TRIP », « nous » ou « notre/nos » désigne TRAP YOUR TRIP S.A.R.L., une société à
                responsabilité limitée enregistrée au Cameroun à cette adresse : n°110, Rue 5828 Santa Barbara / Etoudi
                Yaoundé – Cameroun. « Plateforme » désigne le site Internet (mobile) et l'application sur lesquels le
                Service de Prestations est disponible, détenu, contrôlé, géré, alimenté et/ou hébergé par TRAP YOUR
                TRIP.
            </p>
            <p>
                « Prestation » désigne les différents produits et services que vous pouvez commander, acquérir, acheter,
                payer, louer, fournir, réserver, associer ou consommer auprès du Prestataire Partenaire.
            </p>
            <p>
                Prestataire Partenaire » désigne le fournisseur de l'hébergement (par ex. l'hôtel, le motel,
                l'appartement, la chambre d'hôtes, le propriétaire), de centre de loisirs (par ex. le parc (à thème), le
                musée, la visite touristique), du transport (par ex. de la location de voiture, de la croisière, du
                billet de train, du transfert aéroport, de la visite en car, du transfert, de transport interurbain), le
                tour-opérateur, rendu disponible de temps à autre sur la Plateforme pour une Réservation/Commande de
                Prestation (B2B ou B2C).
            </p>
            <p>
                « Service de Prestation» désigne le service de commande, d'achat, de paiement (facilité) ou de
                réservation en ligne des différents produits et services proposés ou rendus disponibles de temps à autre
                par les Prestataires Partenaires sur la Plateforme TRAP YOUR TRIP.
            </p>
            <p>
                « Réservation de Prestation » désigne la commande, l'achat, le paiement ou la réservation d'une
                Prestation.
            </p>
        </div>
        <div>
            <h2>
                1. Étendue et définition de notre service
            </h2>
            <p>
                À travers cette Plateforme, nous (TRAP YOUR TRIP et ses partenaires (de distribution) affiliés)
                fournissons un portail en ligne grâce auquel les Prestaires Partenaires peuvent faire la publicité,
                faire la promotion, vendre et/ou proposer leurs produits et services à la commande, à l'achat, à la
                réservation ou à la location, dans le cadre de leur étendue commerciale (par ex., B2B ou B2C), et grâce
                auquel les visiteurs de la Plateforme peuvent consulter, rechercher ou comparer les produits et services
                concernés et passer une commande, réserver ou encore effectuer un achat ou un paiement (pour le Service
                de Prestation choisi). En utilisant le Service de Prestation (par ex., en effectuant une Réservation de
                Prestation par l’intermédiaire du Service de Prestation), vous vous engagez dans une relation
                contractuelle directe (juridiquement contraignante) avec le Prestataire Partenaire auprès duquel vous
                avez effectué une réservation ou acheté un produit ou service (le cas échéant). À partir du moment où
                vous effectuez une Réservation de Prestation Touristique, nous agissons uniquement en qualité
                d’intermédiaire entre vous et le Prestataire Partenaire, en transmettant les détails de votre
                réservation au(x) Prestataire(s) Partenaire concerné(s) et en vous
                envoyant un e-mail de confirmation pour et au nom du Prestataire Partenaire. TRAP YOUR TRIP ne (re)vend,
                loue ou propose aucun produit ou service (de voyage).
            </p>
            <p>
                Lors de la prestation de notre Service de Prestation, les informations que nous communiquons se fondent
                sur les informations que nous donnent les Prestataires Partenaires. Bien que nous nous efforcions de
                fournir un Service de Prestation de qualité, nous ne pouvons ni vérifier, ni garantir l'exactitude, la
                précision ou l'exhaustivité des informations, et nous ne pouvons être tenus pour responsables d'aucune
                erreur (erreur manifeste ou faute typographique) ni interruption de service (due à une défaillance
                technique, qu’elle soit temporaire et/ou partielle ou qu'il s'agisse d'une panne, réparation, mise à
                jour, amélioration ou maintenance de notre Plateforme ou autre), d'aucune information imprécise,
                trompeuse ou erronée, ou bien d'un quelconque manque d’information. Chaque Prestataire Partenaire
                demeure responsable à tout moment du caractère précis, complet et exact des informations (descriptives)
                qui le concernent et qui sont affichées sur notre Plateforme, y compris de ses tarifs/frais/prix,
                conditions et disponibilités. Notre Plateforme ne constitue pas et ne doit pas être considérée comme une
                quelconque forme de recommandation ou d’approbation de la qualité, du niveau de service, du classement
                ou de la catégorisation (par étoile), du type d'hébergement de chaque Prestataire Partenaire (ou de ses
                équipements, attractions, véhicules, produits ou services principaux et secondaires) proposé aux
                visiteurs, sauf indication contraire explicitement indiquée.
            </p>
            <p>
                Notre Service de Prestation est uniquement disponible pour une utilisation privée et non commerciale.
                Vous ne pouvez donc pas revendre, utiliser, copier, surveiller (par exemple, par le biais d’un robot
                d’indexation ou de captures de données d’écran), afficher, télécharger, reproduire ou établir des liens
                profonds vers tout contenu ou toute information, réservation, tout logiciel, billet et/ou tous produits
                ou services disponibles sur notre Plateforme dans le cadre d’une activité ou d’un objectif commercial ou
                concurrentiel.
            </p>
            <h2>
                2. Tarifs
            </h2>
            <p>
                Les tarifs proposés par les Prestataires Partenaires sur notre Plateforme se veulent être compétitifs.
                Tous les tarifs affichés pour votre séjour comprennent la TVA, les taxes de vente (soumises à
                modification) et les frais, sauf mention contraire sur notre Plateforme ou sur l’e-mail de confirmation
                de réservation/le billet. Les tarifs des
                billets sont indiqués par personne ou par groupe et sont soumis à validité ou expiration comme précisé
                sur le billet, le cas échéant. Les frais et taxes applicables (y compris la taxe de séjour/touristique)
                peuvent être facturés par le Prestataire Partenaire en cas de non-présentation ou d'annulation.
            </p>
            <p>
                Des tarifs inférieurs sont parfois disponibles sur notre Plateforme à certaines dates pour un séjour,
                produit ou service donné. Cependant, ces tarifs inférieurs proposés par les Prestataires Partenaires
                peuvent être soumis à des conditions et à des restrictions spécifiques, notamment en ce qui concerne les
                conditions d’annulation et de non-remboursement. Veuillez consulter avec attention les informations sur
                le produit, le service ou la réservation concerné(e) pour prendre connaissance desdites conditions avant
                d’effectuer votre réservation.
            </p>
            <p>
                Les erreurs évidentes (y compris les erreurs typographiques) ne sont pas exécutoires.
            </p>
            <p>
                Toutes les offres spéciales et promotions sont indiquées comme telles. Si elles ne sont pas indiquées
                comme telles, vous ne pouvez vous prévaloir d'aucun droit en cas d'erreur manifeste.
            </p>
        </div>
        <div>
            <h2>
                3. Confidentialité des informations et cookies
            </h2>
            <p>
                TRAP YOUR TRIP respecte vos données personnelles.
            </p>
            <h2>
                4. Gratuit pour les consommateurs, seuls les Prestataires Partenaires paient !
            </h2>
            <p>
                Sauf mention contraire, notre service est gratuit pour les consommateurs car, à la différence de
                nombreuses autres sociétés, nous ne vous facturerons pas pour notre Service de Prestation et nous
                n'ajouterons aucun frais supplémentaires (de réservation) au tarif. Vous paierez le montant
                correspondant tel qu'indiqué sur la Réservation de Prestation (plus, dans la mesure où ils ne sont pas
                compris dans le tarif, les taxes, impôts et frais applicables, le cas échéant) au Prestataire
                Partenaire.
            </p>
            <p>
                Les Prestataires Partenaires paient une commission (correspondant à un petit pourcentage du prix du
                produit (par ex. le tarif de l'hébergement)) à TRAP YOUR TRIP, versée après que le client a consommé le
                service ou le produit du Prestataire Partenaire ou après que le client a séjourné chez (et payé)
                l’hébergeur. Seuls les Prestataires Partenaires ayant une relation commerciale avec TRAP YOUR TRIP (dans
                le cadre d'un accord) seront rendus disponibles sur la Plateforme (pour la promotion de leur produit B2B
                et/ou B2C). TRAP YOUR TRIP n'est pas une plateforme ouverte (comme Amazon ou eBay) sur laquelle les
                utilisateurs finaux peuvent rendre leur produit disponible (il ne s'agit pas d'une plateforme C2C). TRAP
                YOUR TRIP ne permet pas aux parties non professionnelles de proposer ou de vendre leurs produits sur ou
                via TRAP YOUR TRIP.
            </p>
            <h2>
                5. Carte de crédit, Paiement Mobile
            </h2>
            <p>
                Si applicable et disponible, certains Prestataires Partenaires offrent la possibilité que les
                Réservations de Prestations soient payées (en totalité ou partiellement, selon les conditions de
                paiement du Prestataire Partenaire) au Prestataire Partenaire lors de la Réservation de Prestation grâce
                à des moyens de paiement en ligne sécurisés (dans la mesure de l'offre proposée par votre banque). Pour
                certains produits et services, TRAP YOUR TRIP s'occupe du paiement (via un processeur de paiement tiers)
                du produit ou service concerné (par ex. : le service facilitant le paiement) pour et au nom du
                Prestataire Partenaire concerné (TRAP YOUR TRIP n'agit et n'opère jamais en tant que commerçant). Le
                paiement est réalisé de manière sécurisée depuis votre carte de crédit/débit ou votre compte mobile, par
                l'intermédiaire d’un processeur de paiement tiers. Tout paiement que nous gérons pour vous et
                transférons au Prestataire Partenaire constitue dans chaque cas le règlement définitif du tarif dû de la
                réservation/commande (ou d'une partie de celui-ci) en votre nom pour le produit ou service correspondant
                et il ne vous sera pas possible de demander le remboursement de cette somme.
            </p>
            <p>
                Pour certains tarifs (non remboursables) ou offres spéciales, veuillez noter que les Prestataires
                Partenaires se réservent le droit de demander un paiement à l'avance par transfert mobile (si
                disponible) ou par carte de crédit et par conséquent une préautorisation de votre carte bancaire ou un
                paiement mobile d'un certain montant (parfois sans possibilité de remboursement) sur cette même
                carte/compte mobile lors de la Réservation de Prestation. Avant d'effectuer votre Réservation de
                Prestation, veuillez consulter les détails (de la réservation) du produit ou service de votre choix pour
                prendre connaissance de telles conditions. TRAP YOUR TRIP ne pourra être tenue pour responsable de tout
                prélèvement (autorisé, (prétendument) non autorisé ou erroné) effectué par le Prestataire Partenaire. Il
                ne vous sera pas possible de demander le remboursement de tout prélèvement valide ou autorisé par le
                Prestataire Partenaire sur votre carte de crédit/compte mobile (y compris pour les tarifs avec
                prépaiement, les non-présentations et les annulations payantes).
            </p>
        </div>
        <div>
            <h2>
                6. Prépaiement, annulation, non-présentation et informations importantes
            </h2>
            <p>
                En effectuant une Réservation de Prestation avec un Fournisseur de Prestation, vous reconnaissez avoir
                lu et accepté les conditions d’annulation et de non-présentation, ainsi que toutes les conditions
                supplémentaires (de prestation) pouvant s’appliquer à votre Prestation (notamment les informations
                importantes du Prestataire Partenaire disponibles sur notre Plateforme et les règles de la maison du
                Prestataire Partenaire), y compris pour les services rendus et/ou les produits proposés par le
                Prestataire Partenaire. Les conditions générales d’annulation et de non-présentation sont indiquées sur
                les pages d'informations du Fournisseur de Prestation de notre Plateforme ; elles sont également
                rappelées au cours du processus de réservation et dans l’e-mail ou le billet (le cas échéant) de
                confirmation. Veuillez noter que certains tarifs, frais ou certaines offres spéciales ne permettent pas
                d'annuler, de modifier la réservation ou de prétendre à un remboursement. Les taxes de séjour
                applicables peuvent être prélevées par le Fournisseur de Prestation même en cas de non-présentation ou
                d'annulation payante. Vous êtes donc invité(e) à bien prendre connaissance des détails (de la
                réservation) du produit ou service de votre choix, pour être au courant des conditions avant d’effectuer
                votre réservation. Les conditions d'annulation et de prépaiement varient en fonction du type de segment,
                de produit ou de service de chaque Prestation. Veuillez lire attentivement les informations importantes
                (indiquées en dessous des types de Prestation ou en bas de la page de chaque Prestataire Partenaire sur
                notre Plateforme) ainsi que les informations utiles sur votre confirmation de réservation relatives aux
                conditions supplémentaires pouvant être appliquées par le Prestataire Partenaire (concernant notamment
                l'âge minimum, le dépôt de garantie, les lits d'appoint, les petits-déjeuners non compris, les animaux
                domestiques, les cartes acceptées et les conditions spéciales s'appliquant aux groupes, comme des frais
                supplémentaires ou des annulations non possibles). Vous serez considéré responsable de tout retard de
                paiement, de toutes coordonnées bancaires, de carte de crédit ou de débit erronées, de toute carte de
                crédit/débit non valide ou de tous fonds insuffisants et ne pourrez prétendre à aucun remboursement d'un
                prépaiement (non remboursable) sauf indication contraire dans les conditions de (pré)paiement et
                d'annulation du Prestataire Partenaire.
            </p>
            <p>
                Si vous souhaitez consulter, modifier ou annuler votre Réservation de Prestation, veuillez vous reporter
                à l'e-mail de confirmation et suivre les instructions qui y sont indiquées. Veuillez noter que toute
                annulation peut entraîner des frais, conformément aux conditions d'annulation, de (pré)paiement et de
                non-présentation et qu'il est possible que les montants (pré)payés ne soient pas remboursés. Nous vous
                conseillons de lire attentivement ces conditions d'annulation, de (pré)paiement et de non-présentation
                avant d'effectuer votre réservation et d'effectuer tout paiement à venir dans les délais convenus pour
                ladite réservation.
            </p>
            <p>
                En cas d'arrivée tardive le jour de l'enregistrement ou d'arrivée repoussée au lendemain, assurez-vous
                d'en informer le Prestataire Partenaire (en temps utile/rapidement) afin qu'il soit mis au courant et
                n'annule pas votre (Réservation de) Prestation ni ne vous facture pour non-présentation. Notre Service
                Clients peut vous aider, le cas échéant, à informer le Prestataire Partenaire. TRAP YOUR TRIP n'est en
                aucun cas tenue responsable des conséquences liées à votre arrivée tardive ou de toute facturation
                d'annulation ou de non-présentation réalisée par le Prestataire Partenaire.
            </p>
            <h2>
                7. Correspondances et communications (futures)
            </h2>
            <p>
                En effectuant une Réservation de Prestation, vous acceptez de recevoir (i) un e-mail que nous pourrons
                vous faire parvenir avant votre date d’arrivée dans l'établissement, qui contiendra des informations sur
                votre destination et des offres pertinentes pour votre (Réservation de) Prestation (y compris des offres
                provenant de nos partenaires, si vous avez accepté de les recevoir), (ii) un e-mail après votre arrivée
                pour évaluer (votre expérience avec) le Prestataire Partenaire et le Service de Prestation, et (iii) un
                e-mail que nous pourrons vous envoyer après la fin de votre séjour dans l'établissement, et qui vous
                invitera à remplir notre questionnaire de commentaires clients. Veuillez consulter notre page Contacts
                pour obtenir plus de renseignements sur la façon dont nous pouvons vous contacter.
            </p>
            <p>
                TRAP YOUR TRIP exclut toute responsabilité lors de communications avec le Prestataire Partenaire sur ou
                via sa plateforme. Vous ne pouvez vous prévaloir d'aucun droit découlant des demandes ou communications
                avec le Prestataire Partenaire ou de (toute forme d') accusé de réception de toute communication ou
                demande. TRAP YOUR TRIP ne peut garantir que toute demande ou communication sera (dûment et en temps
                utile) reçue/lue, acquittée, effectuée ou acceptée par le Prestataire Partenaire.
            </p>
            <p>
                Afin de finaliser et garantir votre Réservation de Prestation comme il se doit, vous êtes tenu(e)
                d'utiliser votre bonne adresse e-mail. Nous excluons toute responsabilité (et ne sommes pas tenus de
                vérifier) toute adresse e-mail, numéro de téléphone, carte de crédit ou compte mobile invalides ou mal
                renseignés.
            </p>
            <p>
                Toute plainte ou réclamation à l'encontre de TRAP YOUR TRIP ou liée au Service de Prestation doit être
                soumise rapidement, dans les 30 jours suivant le jour de la fin du produit ou service (par exemple, la
                date de départ de l'établissement). Toute plainte ou réclamation soumise après cette période de 30 jours
                pourra être rejetée et le demandeur pourra perdre le droit d'exiger une compensation (dommage ou coût).
            </p>
            <p>
                En raison des mises à jour et modifications effectuées régulièrement au niveau des tarifs et
                disponibilités, nous vous conseillons vivement de prendre des captures d'écran lorsque vous effectuez
                une réservation afin d'appuyer votre demande (le cas échéant).
            </p>
            <p>
                Pour les clients ressortissants de l'Espace économique européen : nous vous recommandons de contacter
                notre Service Clients pour lui signaler toute réclamation.
            </p>
        </div>
        <div>
            <h2>
                8. Classement, programme de partenariat Établissements Préférés, étoiles et commentaires clients
            </h2>
            <p>
                Nous vous proposons par défaut un classement personnalisé des Prestataires Partenaires afin de garantir
                que les résultats de recherche que vous obtenez sur notre Plateforme s'adaptent à vos besoins. Vous
                pouvez consulter ce classement par défaut ou modifier la présentation des résultats en utilisant des
                filtres et en triant en fonction de différents critères.
            </p>
            <p>
                Le nombre d'étoiles de l'établissement affiché sur TRAP YOUR TRIP n'est pas déterminé par TRAP YOUR
                TRIP. Le nombre d'étoiles est déterminé par l'établissement lui-même ou par un fournisseur d'étoiles
                (objectives) indépendant. Les offres apparaissent dans l'ordre du nombre d'étoiles (ordre croissant ou
                décroissant) proposé par le fournisseur à TRAP YOUR TRIP. Selon les règles (locales), les étoiles sont
                attribuées par un tiers (indépendant), par exemple une organisation
                (officielle) s'occupant des classifications des hôtels, ou sont basées sur l'opinion des établissements
                eux-mêmes, indépendamment de tout critère objectif. TRAP YOUR TRIP n'impose aucune obligation formelle
                en matière du nombre d'étoiles et ne vérifie pas cette information. Les étoiles permettent d'évaluer
                l'établissement en fonction des exigences réglementaires (le cas échéant) ou, si aucune régulation n'est
                en place, en fonction des normes du secteur hôtelier concernant les tarifs, les équipements et les
                services proposés (ces exigences et normes peuvent varier selon les différents pays et organisations).
            </p>
            <p>
                Seuls les clients ayant consommé une prestation seront invités par TRAP YOUR TRIP à laisser une
                appréciation à certains aspects de leur prestation. Eux seuls pourront recevoir une demande d'évaluation
                au cours de la consommation de leur prestation. Les appréciations (y compris ceux envoyés durant votre
                séjour) peuvent être (a) affichées sur notre Plateforme, sur la page du Prestataire Partenaire concerné,
                dans le seul but d’informer les (futurs) clients des opinions sur le (niveau de) service et la qualité
                du Prestataire Partenaire et (b) utilisés (partiellement ou intégralement) par TRAP YOUR TRIP à sa seule
                discrétion (à des fins marketing ou promotionnelles, ou pour améliorer nos services) sur notre
                Plateforme ou sur des réseaux sociaux, newsletters, offres spéciales, applications ou autre moyen
                utilisé, géré ou contrôlé par TRAP YOUR TRIP ou par nos partenaires commerciaux. Afin de proposer
                constamment des appréciations récentes (et donc pertinents), les commentaires peuvent uniquement être
                laissés au cours d'une période limitée (3 mois) après la fin de la consommation de la prestation et sont
                également affichés pendant une période limitée (jusqu'à 36 mois) après leur publication. Le
                questionnaire des commentaires clients est considéré comme un sondage et, à ce titre, il ne comprend
                aucune offre commerciale, invitation ou récompense supplémentaire.
            </p>
            <p>
                TRAP YOUR TRIP et le Prestataire Partenaire sont chacun en droit de mettre fin à leur partenariat quelle
                qu'en soit la raison (y compris en cas de manquement commis au regard du présent contrat ou de (mise en)
                faillite), dans le respect du délai de préavis tel que convenu entre les parties.
            </p>
        </div>
        <div>
            <h2>
                9. Limitation de responsabilité
            </h2>
            <p>
                Conformément aux limites établies dans les présentes conditions générales d’utilisation sauf disposition
                d’ordre public nationale contraire, nous ne pouvons être tenus responsables que si vous avez souffert,
                payé ou subi des dommages directs pouvant être imputés à un défaut de nos obligations dans le cadre de
                nos services. Ces dommages peuvent s’élever jusqu’au montant cumulé du coût total de votre réservation,
                tel qu’indiqué dans l’e-mail de confirmation de la Réservation de la Prestation, qu’il s’agisse d’un
                événement isolé ou d’une série d’événements liés.
            </p>
            <p>
                Néanmoins et sauf disposition d’ordre public nationale contraire, ni nous ni nos dirigeants, cadres,
                employés, représentants, filiales, affiliés, distributeurs, partenaires (de distribution), titulaires de
                sous-licences, agents ou autres personnes impliquées dans la création, le sponsoring, la promotion ou la
                mise à disposition du site et de ses contenus, ne pouvons être tenus responsables pour (i) toutes pertes
                ou tous dommages punitifs, spéciaux, indirects ou consécutifs, perte de production, perte de profit,
                perte de revenu, perte d’opportunité commerciale, perte ou atteinte à la marque ou à la réputation, ou
                bien perte du droit à indemnité ; (ii) toute inexactitude liée aux informations (descriptives) du
                Prestataire Partenaire (y compris ses tarifs, ses disponibilités et son classement) mises à disposition
                sur notre Plateforme ; (iii) tous services fournis ou produits proposés par le Prestataire Partenaire ou
                d’autres partenaires commerciaux ; (iv) tous dommages, pertes et coûts (punitifs, spéciaux, indirects ou
                consécutifs) soufferts, subis ou payés par vous, imputables à, découlant de ou liés à l’utilisation de
                notre Plateforme, de l’impossibilité de son utilisation ou de son ralentissement de fonctionnement ; ou
                (v) tout dommage corporel, décès, dommage aux biens ou autres dommages, pertes ou coûts (directs,
                indirects, spéciaux, consécutifs ou punitifs) soufferts, subis ou payés par vous, qu’ils soient dus à
                des actes (légaux), des erreurs, des manquements, une négligence (grave), une faute professionnelle
                délibérée, des omissions, une inexécution de contrat, des dénaturations des faits, au tort ou à la
                responsabilité objective attribuable (entièrement ou partiellement) au Prestataire Partenaire ou à l'un
                de nos autres partenaires commerciaux (y compris ses employés, sa direction, ses responsables, ses
                agents, ses représentants, ses sous-traitants ou ses affiliés) dont les produits et services sont
                (directement ou indirectement) proposés ou promus sur ou via notre Plateforme, y compris toute
                annulation (même partielle), surréservation, grève, cas de force majeure ou autre événement indépendant
                de notre volonté.
            </p>
            <p>
                TRAP YOUR TRIP n'est pas responsable de (et décline toute responsabilité à cet égard) l'utilisation, la
                validité, la qualité, l’adéquation, la pertinence et la divulgation de Prestation, et n'assume aucune
                représentation, garantie ou condition à cet égard, qu'elles soient tacites, légales ou autres, y compris
                toute garantie implicite de qualité marchande, de titre, de non-contrefaçon ou de pertinence dans un but
                précis. Vous reconnaissez et acceptez l'entière responsabilité du Prestataire Partenaire concerné dans
                le cadre de la Prestation (y compris pour toute garantie et représentation assumée par le Prestataire
                Partenaire). TRAP YOUR TRIP n'agit pas en tant que (re)vendeur de Prestation. Toute plainte ou
                réclamation concernant la Prestation (y compris relative au tarif (spécial/promotionnel) proposé, aux
                conditions ou aux demandes spéciales effectuées par le Client) sera traitée par le Prestataire
                Partenaire. TRAP YOUR TRIP n'est pas responsable de ces plaintes et réclamations, ou du produit, et
                décline toute responsabilité à cet égard.
            </p>
            <p>
                Que le Prestataire Partenaire vous facture (ou vous ait facturé) pour votre Prestation ou que nous
                facilitions le paiement du tarif ou des frais (de la Prestation), vous reconnaissez et acceptez que le
                Prestataire Partenaire est à tout moment responsable de la collecte, de la retenue, du versement et du
                paiement des taxes applicables dues sur le montant total du tarif ou des frais (de Prestation) aux
                autorités financières concernées. TRAP YOUR TRIP n’est ni responsable ni redevable du versement, de la
                collecte, de la retenue ou du paiement des taxes applicables concernées dues sur le tarif ou les frais
                (de Prestation) aux autorités financières concernées. TRAP YOUR TRIP n'agit pas en tant que commerçant
                pour les produits ou services disponibles sur la Plateforme.
            </p>
            <p>
                En partageant avec TRAP YOUR TRIP des photos/images (par exemple en envoyant une appréciation), vous
                certifiez et garantissez posséder le droit d’auteur des photos/images et vous acceptez que TRAP YOUR
                TRIP utilisent ces photos/images téléchargées sur ses sites Internet (mobile), applis, outils et
                publications promotionnelles (en ligne et hors ligne) lorsque TRAP YOUR TRIP le jugera, à sa discrétion,
                approprié. Vous accordez à TRAP YOUR TRIP un droit non exclusif, universel, irrévocable, inconditionnel
                et perpétuel ainsi que l’autorisation d’utiliser, reproduire, afficher, faire reproduire, distribuer,
                communiquer, accorder un droit de sous-licence et rendre disponible les photos/images lorsque TRAP YOUR
                TRIP le jugera, à sa discrétion, approprié. En partageant avec TRAP YOUR TRIP ces photos, la personne
                les ayant partagées accepte l’entière responsabilité morale et juridique de
                toutes les plaintes juridiques faites par toute partie tiers (y compris, mais non limitée aux
                propriétaires d’hébergements) suite à l’affichage et l’utilisation de ces photos/images par TRAP YOUR
                TRIP. TRAP YOUR TRIP ne possède ni n’approuve les photos/images reçues. La véracité, la validité et le
                droit d’utilisation de toutes les photos/images téléchargées sont assumés par la personne ayant effectué
                le partage (en vue de publication sur notre Plateforme) de la photo/image et ne sont pas de la
                responsabilité de TRAP YOUR TRIP. TRAP YOUR TRIP décline toute responsabilité concernant les
                photos/images postées. La personne ayant effectué le partage (en vue de publication sur notre
                Plateforme) des photos/images assure que celles-ci ne contiennent aucun virus, cheval de Troie ou
                fichier infecté et n’ont aucun caractère pornographique, illégal, obscène, insultant, répréhensible ou
                inapproprié et n’enfreignent pas les droits de toute partie tiers (droit sur la propriété intellectuel,
                droit d’auteur et droit au respect de la vie privée). Toute photo/image ne remplissant pas les critères
                susmentionnés ne sera pas publiée et/ou pourra être effacée/supprimée à tout moment par TRAP YOUR TRIP
                sans notification préalable.
            </p>
            <h2>
                10. Droits de propriété intellectuelle
            </h2>
            <p>
                Sauf mention contraire, le logiciel nécessaire pour nos services ou mis à disposition sur notre
                Plateforme et utilisé par ce dernier, ainsi que les droits de propriété intellectuelle (y compris les
                droits d’auteur) des contenus, informations et matériels de notre Plateforme, sont la propriété
                exclusive de TRAP YOUR TRIP, de ses Prestataires Partenaires.
            </p>
            <p>
                TRAP YOUR TRIP demeure l’unique propriétaire de tous les droits, titres et intérêts (relatifs à tous les
                droits de propriété intellectuelle) de la Plateforme (sa présentation et son infrastructure y compris)
                sur lequel le service est disponible (commentaires clients et traductions y compris). Par conséquent,
                vous n’êtes pas autorisé à copier, créer des liens (hypers ou profonds) vers notre contenu, publier,
                promouvoir, commercialiser, intégrer ou utiliser de toute autre manière notre contenu (y compris ses
                traductions et les commentaires clients) ou notre marque sans notre autorisation écrite formelle. Dans
                la mesure où vous utiliseriez (une partie ou l’intégralité de) notre contenu (traductions et
                commentaires clients inclus) ou détiendriez des droits de propriété intellectuelle sur notre Plateforme,
                notre contenu (traduit) ou nos commentaires clients, vous devrez céder, et transférer tous ces droits à
                TRAP YOUR TRIP. Toute utilisation illégale ou action susmentionnée constituera une violation
                substantielle de nos droits de propriété intellectuelle (droit d’auteur et droit sur la base de données
                inclus).
            </p>
            <h2>
                11. Lois en vigueur, juridiction et règlement des litiges
            </h2>
            <p>
                Les présentes conditions générales d’utilisation et la prestation de nos services sont soumises à et régies par les normes OHADA.
            </p>
        </div>
    </div>
</div>