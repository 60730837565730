import { Component, OnInit } from '@angular/core';
import { Reservation } from 'src/app/models/reservation.model';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { Router } from '@angular/router';
import { PanierService } from 'src/app/services/panier.service';
import { Subscription } from 'rxjs';
import { Trajet } from 'src/app/models/trajet.model';
import * as firebase from 'firebase';
import { Paiement } from 'src/app/models/paiement.model';
declare const metro: any;

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss']
})
export class PanierComponent implements OnInit {

  paiements = new Array<Paiement>();
  panierSubscription: Subscription;
  TOTAL = 0;
  isUser = false;
  constructor(
    private authService: AuthentificationService,
    private router: Router,
    private panierService: PanierService
  ) { }

  ngOnInit(): void {
    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('paiement-trap').get().then((resultats) => {
      resultats.forEach((resultat) => {
        const paiement = resultat.data() as Paiement;
        if (paiement.reservations.length > 0) {
          this.paiements.push(paiement);
        }
        this.paiements.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? -1 : 1;
        });
      });
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

  suivant() {
    if (this.authService.utilisateur) {
      this.router.navigate(['dashboard', 'paiement', 'edit']);
    } else {
      this.router.navigate(['inscription']);
    }

  }

  voir(paiement: Paiement) {
      this.router.navigate(['offres', 'reservation', 'recap', paiement.id]);
  }

  modifier(reservation: Reservation) {
    this.router.navigate(['offres', 'reservation', 'view', reservation.id]);
  }


}
