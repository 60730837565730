<div class="page" style="min-height: 100vh; padding-top: 10px;">

    <h1>
        Ajouter une ville
    </h1>


    <div class="contenu">
        <div class="row">
            <div class="col-lg-4">
                <input type="text" [(ngModel)]="nom" data-role="input" data-prepend="Nom">
            </div>
            <div class="col-lg-4">
                <select type="text" [(ngModel)]="pays" data-role="select" data-prepend="Pays">
                    <option *ngFor="let p of lesPays" [value]="p.name">
                        {{p.name}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4">
                <button class="button" (click)="enregistrer()">Enregistrer</button>
            </div>
        </div>
    </div>
</div>