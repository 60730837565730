<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
    <div class="page-sejour">
        <div class="entete" style="background-color: #eee;">
            <div class="entete__background">
            </div>
            <div class="entete__contenu">
                <div class="row">
                    <div class="cell-lg-9">
                        <div class="titre">
                            {{'Séjours' | traduction}}
                            <a class="button small showOnMObile" (click)="rechercheFilter()" style="float: right; margin-left: 10px;">
                                <i class="mif-search fg-blue"></i>
                            </a>
                            <a class="button small showOnMObile" (click)="toggleFilter()" style="float: right; margin-left: 10px;">
                                <i class="mif-filter fg-blue"></i>
                            </a>
                        </div>
                        <div class="sous-titre text-leader2" style="font-size: 0.9em;">
                            {{'Retrouvez les meilleurs séjours' | traduction}}
                        </div>
                    </div>
                    <div class="cell-lg-3">
                        <app-admin>
                            <div class="administration">
                                <button class="button primary" style="background-color: rgb(48, 164, 221);" (click)="nouveau()">
                                    Nouveau
                                </button>
                            </div>
                        </app-admin>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenu" style="padding-top: 15px;">

            <div *ngIf="recherchesShowed" class="mobile-recherche" style="margin-top: -30px; margin-bottom: 10px;">
                <div class="row no-gap shadow-1">
                    <div class="cell-lg-5 bordure-orange-debut">
                        <div *ngIf="villes.length > 0">
                            <select (change)="rechercheFilter()" #ville [attr.data-prepend]="'Ville' | traduction" data-role="select">
                                <option value="">{{'Toutes les villes' | traduction}}</option>
                                <option *ngFor="let v of villes" [value]="v.nom">
                                    {{v.nom}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="villes.length === 0">
                            <input data-role="input">
                        </div>
                    </div>
                    <div class="cell-lg-4 bordure-orange-fin">
                        <div>
                            <input #calendarpickerlocale type="date" [placeholder]="'A partir du' | traduction" data-role="calendarpicker">
                        </div>
                    </div>
                    <div class="cell-lg-3 bordure-orange-fin">
                        <div>
                            <a (click)="rechercheFilter()" class="button warning" style="width: 100%; background-color: rgb(48, 164, 221); color: white">
                                {{'Rechercher' | traduction}}
                            </a>
                        </div>
                    </div>
                    <div class="showOnMObile mobile-recherche-fermer cell-lg-2">
                        <a (click)="rechercheFilter()" class="button dark" style="color: #fff;  width: 100%;">{{'Fermer' | traduction}}</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="cell-lg-2">
                    <div *ngIf="filtersShowed" class="les">
                        <div (click)="toggleFilter()" class="fermer-options showOnMObile">

                        </div>
                        <div class="les-options">
                            <div class="les-options-contenant">
                                <div class="fermer-filtre" (click)="toggleFilter()">
                                    <button class="button small">
                                        X
                                    </button>
                                </div>
                                <div class="les-options-contenu">
                                    <div class="options-titres">
                                        {{'Filtres populaires' | traduction}}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="relaxation" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Relaxation' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="pieds" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Pieds dans le sable' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="phasenature" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'En phase avec la nature' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="sportif" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Sportif' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="gastronomique" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Découverte gastronomique'  | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="insolite" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Hébergement insolite' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="festive" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Ambiance festive' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="affaire" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Séjour d`affaire' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="culturel" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Evènement culturel' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="animaux" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'A la rencontre des animaux' | traduction">
                                        </span>
                                    </div>

                                    <div class="options-titres">
                                        {{'Activités' | traduction}}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="spa" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Accès au spa' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="soin" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Soin de corps' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="massage" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Massage et modélage' | traduction">
                                        </span>
                                    </div>

                                    <div class="options-titres">
                                        {{'Dîner' | traduction}}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="pension" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Demi pension' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="diner" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Dîner' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="degustation" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Dégustation' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="pensioncomplete" type="checkbox"
                                                data-role="checkbox" [attr.data-caption]="'Pension complète' | traduction">
                                        </span>
                                    </div>
                                    <div class="options-titres">
                                        Culture
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="visiteguidee" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Visite guidée' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="autresvisites" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Autres visites' | traduction">
                                        </span>
                                    </div>

                                    <div class="options-titres">
                                        Sport
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="golf" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Golf à proximité' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="sallesport" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Forfait inclus salle de sport' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="velo" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Vélo' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="tennis" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Accès au terrain de tennis' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="basket" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Accès au terrain de basket' | traduction">
                                        </span>
                                    </div>
                                    <div class="options-titres">
                                        {{'Divertissement' | traduction }}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="ponctuel" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Evènement ponctuel' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="attraction" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Parc d`attraction' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="spectacle" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Spectacle' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="zoo" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Zoo' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="foire" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Foire' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="randonnee" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Randonnée' | traduction">
                                        </span>
                                    </div>
                                    <div class="options-titres">
                                        {{'Catégorie propriété' | traduction}}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="maisonvacances" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Maison de vacances' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="appartementvacances" type="checkbox"
                                                data-role="checkbox" [attr.data-caption]="'Appartement de vacances' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="chambrehotel" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Chambre d`hôtel' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="villagevacances" type="checkbox"
                                                data-role="checkbox" [attr.data-caption]="'Village de vacances' | traduction">
                                        </span>
                                    </div>
                                    <div class="options-titres">
                                        {{"Lieu de prise en charge de l'activité" | traduction}}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="hotel" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Hôtel' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="pointrencontre" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Point de rencontre' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="lieuactivite" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Lieu de l`activité' | traduction">
                                        </span>
                                    </div>
                                    <div class="options-titres">
                                        {{'Langues disponibles' | traduction}}
                                    </div>
                                    <div class="options">
                                        <span class="">
                                            <input formControlName="francais" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Français' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="anglais" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Anglais' | traduction">
                                        </span>
                                        <span class="">
                                            <input formControlName="arabe" type="checkbox" data-role="checkbox"
                                                [attr.data-caption]="'Arabe' | traduction">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cell-lg-10">
                    <div class="row muanza-padding-mobile">
                        <div class="cell-lg-4" *ngFor="let sejour of resultats">
                            <app-display-sejour [sejour]="sejour"></app-display-sejour>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>