import { Component, OnInit } from '@angular/core';
import { Ville } from 'src/app/models/ville.model';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { PAYS } from 'src/app/data/pays';

declare const metro: any;

@Component({
  selector: 'app-ville-edit',
  templateUrl: './ville-edit.component.html',
  styleUrls: ['./ville-edit.component.scss']
})
export class VilleEditComponent implements OnInit {

  nom: string;
  pays = 'Cameroun';

  lesPays = PAYS;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  enregistrer() {
    if (this.nom && this.pays) {
      const ville = new Ville(this.nom, this.pays);
      this.saveVille(ville);
    } else {
      alert('Nom ou Pays');
    }
  }

  saveVille(ville: Ville) {

    const activity = metro().activity.open({
      type: 'square',
      overlayColor: '#fff',
      overlayAlpha: 0.8
    });

    const db = firebase.firestore();
    db.collection('ville-trap').doc(ville.id).set(JSON.parse(JSON.stringify(ville))).then((resultats) => {
      console.log('TERMINEEE !!!');
      metro().activity.close(activity);
      this.router.navigate(['ville']);
    }).catch((e) => {
      metro().activity.close(activity);
    });
  }

}
