<div *ngIf="divertissement" [ngClass]="{'passee': passee}" class="sejour" (click)="ouvrir(divertissement.id)">
    <div class="sejour__conteneur">
        <div class="sejour__image" [ngStyle]="{'background-image': 'url(' + divertissement.images[0] +')'}">

        </div>
        <div class="sejour__prix">
            <span>
                {{divertissement.prix | conversion | currency:devise:'symbol':'1.2-2':fuseau}}
            </span>
        </div>
    </div>
    <div class="row" style="font-size: 1.2em;">
        <div class="cell-8">
            <div class="sejour__description">
                {{divertissement.titre}}
            </div>
            <div>
                <b *ngIf="divertissement.ville">
                    {{divertissement.ville}}<ng-container *ngIf="divertissement.lieu" >,</ng-container>
                </b> {{divertissement.lieu}}
            </div>
            <div *ngIf="divertissement.date && !divertissement.dateFin">
                {{divertissement.date | date: 'yyyy-MM-dd' }}
            </div>
            <div *ngIf="divertissement.date && divertissement.dateFin">
                Du {{divertissement.date | date: 'yyyy-MM-dd' }} au {{divertissement.dateFin | date: 'yyyy-MM-dd' }}
            </div>
        </div>
        <div class="cell-4">
            <div style="padding-top: 10px; text-align: right;">
                <div class="sejour__notation orange" [innerHTML]="notationToStars(divertissement.notation)"></div>


            </div>
        </div>
    </div>
</div>